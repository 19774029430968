/**
 * Module Redux Root Reducer
 *
 * Author Matic Kogovšek [kogovsek.matic@gmail.com]
 */
/*!
 * Module dependencies.
 */
import { combineReducers } from 'redux'

import apiLog from './reducers/apiLog'
import auditLog from './reducers/auditLog'
import auth from './reducers/auth'
import dashboard from './reducers/dashboard'
import jackpot from './reducers/jackpot'
import mission from './reducers/mission'
import notification from './reducers/notification'
import notifications from './reducers/notifications'
import report from './reducers/report'
import sidebar from './reducers/sidebar'
import system from './reducers/system'
import tournament from './reducers/tournament'
import translation from './reducers/translation'
import user from './reducers/user'
import util from './reducers/util'
import view from './reducers/view'

/**
 * Redux root reducer
 */
const appReducer = combineReducers({
    view,
    auth,
    util,
    user,
    apiLog,
    system,
    report,
    jackpot,
    mission,
    sidebar,
    auditLog,
    dashboard,
    tournament,
    translation,
    notification,
    notifications,
})

const rootReducer = (state, action) => {
    if (action.type === 'AUTH_LOGOUT_SUCCESS') {
        // for all keys defined in your persistConfig(s)
        // In case you are using redux-persist, you may also need to clean your storage.
        // Redux-persist keeps a copy of your state in a storage engine, and the state copy will be loaded from there on refresh.
        //storage.removeItem('persist:root'); // <-- implemented in middleware/localStorage.js

        // Clear everything
        // (reducers are supposed to return the initial state when they are called with undefined as the first argument,
        // no matter the action. Let’s use this fact to conditionally strip the accumulated state as we pass it to appReducer)
        return appReducer(undefined, action)
    }

    // if not logout action, then use the appropriate reducer from appReducer
    return appReducer(state, action)
}

export default rootReducer
