/**
 * Module Redux Translation Reducer
 */

/*!
 * Module dependencies.
 */
import { localStorageKey } from '../../config/store'

/**
 * Initial store state
 * @type {}
 */
const initialState = {
    translations: [],
    isFetching: false,
    synced: new Date(0),
}
/**
 * Local store
 * @type {Object}
 */
const localStore = JSON.parse(localStorage.getItem(localStorageKey) || '{}')
/**
 * Feed reducer
 * @param state
 * @param action
 * @returns {{edit: {}, saved: {}, error: {}, synced: boolean, list: Array}}
 */
const translationReducer = (state = localStore.translation ? { ...localStore.translation } : initialState, action) => {
    switch (action.type) {
        case 'TRANSLATION_FETCH':
            return {
                ...state,
                isFetching: true,
            }

        case 'TRANSLATION_FETCH_FULFILLED': {
            const translations = action.payload

            const structuredTranslations = translations.reduce((acc, translation) => {
                const { id, type, lang, val } = translation

                Object.entries(val).forEach(([key, value]) => {
                    acc.push({
                        id,
                        type,
                        lang,
                        key,
                        value,
                    })
                })

                return acc
            }, [])

            return {
                ...state,
                translations: structuredTranslations,
                isFetching: false,
                synced: new Date(),
            }
        }

        case 'TRANSLATION_UPDATE':
            return {
                ...state,
                translations: state.translations.find((t) => t._id === action.payload._id)
                    ? state.translations.map((t) => (t._id === action.payload._id ? action.payload : t))
                    : [action.payload, ...state.translations],
                synced: new Date(),
            }

        case 'TRANSLATION_DELETE_FULFILLED':
            return {
                ...state,
                translations: state.translations.filter((translation) => translation.id !== action.payload.id || translation.key !== action.payload.key),
                synced: new Date(),
            }

        default:
            return state
    }
}

export default translationReducer
