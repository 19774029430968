import { localStorageKey } from '../../config/store'

/**
 * Local Storage Middleware
 * @type {string[]}
 */
const localStorageWhitelist = ['jackpot', 'util', 'view', 'system', 'auth', 'notification']

const localStorageMiddlewere =
    ({ getState }) =>
    (next) =>
    (action) => {
        next(action)
        if (action.payload) {
            const state = getState()
            const subset = localStorageWhitelist.reduce((o, k) => {
                o[k] = state[k]
                return o
            }, {})
            try {
                localStorage.setItem(localStorageKey, JSON.stringify(subset)) //localStorage.setItem('tt-backoffice', JSON.stringify(subset));
            } catch (e) {
                if (e.code === '22' || e.code === '1024') {
                    console.log('Quota exceeded!') //data wasn't successfully saved due to quota exceed so throw an error
                }
            }
        }

        // on user logout delete stored state; also check redux/rootReducer
        if (action.type === 'AUTH_LOGOUT_SUCCESS') {
            localStorage.setItem(localStorageKey, '{}') //localStorage.setItem('tt-backoffice', JSON.stringify(subset));
        }
    }

// Export
export default localStorageMiddlewere
