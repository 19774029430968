/**
 * Module Redux Tournament Reducer
 */

/*!
 * Module dependencies.
 */
import { localStorageKey } from '../../config/store'

/**
 * Initial store state
 * @type {}
 */
const initialState = {
    userFavs: [],
    synced: new Date(0),
    userFavsIsFetching: false,
}
/**
 * Local store
 * @type {Object}
 */
const localStore = JSON.parse(localStorage.getItem(localStorageKey) || '{}')

const userReducer = (state = localStore.user ? { ...localStore.user } : initialState, action) => {
    switch (action.type) {
        case 'VIEW_USER_FAV_LINKS_FETCH':
            return {
                ...state,
                userFavsIsFetching: true,
            }
        case 'VIEW_USER_FAV_LINKS_FETCH_FULFILLED':
            return {
                ...state,
                userFavs: action.payload,
                userFavsIsFetching: false,
                synced: new Date(),
            }
        case 'USER_FAV_LINK_DELETE_FULFILLED':
            return {
                ...state,
                userFavs: state.userFavs.filter((uf) => uf.id !== action.payload.id),
                synced: new Date(),
            }
        case 'USER_FAV_LINK_UPDATE_FULFILLED':
            return {
                ...state,
                userFavs: state.userFavs.find((ti) => ti.id === action.payload.id)
                    ? state.userFavs.map((ti) => (ti.id === action.payload.id ? action.payload : ti))
                    : [action.payload, ...state.userFavs],
                synced: new Date(),
            }
        case 'USER_FAV_LINK_UPDATE_DO_NOTHING':
        default:
            return state
    }
}

export default userReducer
