import { applyMiddleware, compose, createStore } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import thunk from 'redux-thunk'

import { localStorageKey } from './config/store'
import localStorageMiddlewhere from './redux/middleware/localStorage'
import rootEpic from './redux/rootEpic'
import rootReducer from './redux/rootReducer'

// Create Epic middleware
const epicMiddleware = createEpicMiddleware()

// Redux middleware
const middleware = [thunk, epicMiddleware, localStorageMiddlewhere]

// Compose enhancers
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// If the user already used the app and did some requests to get for example codebooks for util [games | entities | currencies]
// and then this user opens another tab, then try to restore react state from localStorage - so that the user
// does not need to re-fetch that data
const existingStorageContent = localStorage.getItem(localStorageKey)
let existingStorageData = existingStorageContent ? JSON.parse(existingStorageContent) : {}

// Create Redux store
const store = createStore(
    rootReducer,
    existingStorageData, //{}
    composeEnhancers(applyMiddleware(...middleware))
)

// Run Epic middleware
epicMiddleware.run(rootEpic)

export default store
