import { ofType } from 'redux-observable'
import { from, race, timer } from 'rxjs'
import { map, mapTo, switchMap, throttle } from 'rxjs/operators'

import { api } from '../../config/api'

/**
 * View user favourite links
 * @param action$
 * @returns {*}
 */
export const viewUserFavLinks = (action$) => action$.pipe(ofType('VIEW_USER_FAV_LINKS'), mapTo({ type: 'VIEW_USER_FAV_LINKS_FETCH' }))

/**
 * Fulfill fetch user favourite links
 * @param action$
 * @param state$
 * @returns {*}
 */
export const fetchUserFavLinks = (action$) =>
    action$.pipe(
        ofType('VIEW_USER_FAV_LINKS_FETCH'),
        throttle(() => race(action$.pipe(ofType('VIEW_USER_FAV_LINKS_FETCH_FULFILLED')), timer(5000))),
        switchMap(({ payload }) => from(fetch(api + '/user/favLinks', { credentials: 'include' }).then((res) => (res.status === 200 ? res.json() : null)))),
        map((res) => (res ? { type: 'VIEW_USER_FAV_LINKS_FETCH_FULFILLED', payload: res } : { type: 'USER_FAV_LINK_UPDATE_DO_NOTHING' }))
    )

/**
 * Fulfill user favourite link delete
 * @param action$
 * @param state$
 * @returns {*}
 */
export const deleteUserFavouriteLink = (action$) =>
    action$.pipe(
        ofType('USER_FAV_LINK_DELETE'),
        throttle(() => race(action$.pipe(ofType('USER_FAV_LINK_DELETE_FULFILLED')), timer(5000))),
        switchMap(({ payload }) => from(fetch(api + '/user/favLink/' + payload.id, { credentials: 'include', method: 'DELETE' }).then((res) => (res.status === 200 ? res.json() : null)))),
        map((res) => (res ? { type: 'USER_FAV_LINK_DELETE_FULFILLED', payload: res } : { type: 'USER_FAV_LINK_UPDATE_DO_NOTHING' }))
    )

/**
 * Update/create user favourite links
 * @param action$
 * @param state$
 * @returns {*}
 */
export const updateUserFavLink = (action$) =>
    action$.pipe(
        ofType('USER_FAV_LINK_UPDATE'),
        throttle(() => race(action$.pipe(ofType('USER_FAV_LINK_UPDATE_FULFILLED')), timer(5000))),
        switchMap(({ payload }) =>
            from(
                fetch(api + '/user/favLink', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include',
                    body: JSON.stringify(payload),
                }).then((res) => (res.status === 200 ? res.json() : null))
            )
        ),
        map((res) => (res ? { type: 'USER_FAV_LINK_UPDATE_FULFILLED', payload: res } : { type: 'USER_FAV_LINK_UPDATE_DO_NOTHING' }))
    )
