import { combineEpics } from 'redux-observable'
import { catchError } from 'rxjs/operators'

import * as apiLog from './epics/apiLog'
import * as auditLog from './epics/auditLog'
import * as auth from './epics/auth'
import * as dashboard from './epics/dashboard'
import * as jackpot from './epics/jackpot'
import * as mission from './epics/mission'
import * as notification from './epics/notification'
import * as notifications from './epics/notifications'
import * as report from './epics/report'
import * as system from './epics/system'
import * as tournament from './epics/tournament'
import * as translation from './epics/translation'
import * as user from './epics/user'
import * as util from './epics/util'
import * as view from './epics/view'

/**
 * Combine Epics
 * @type {any[]}
 */
const epics = [
    ...Object.values(auth),
    ...Object.values(view),
    ...Object.values(util),
    ...Object.values(user),
    ...Object.values(apiLog),
    ...Object.values(system),
    ...Object.values(report),
    ...Object.values(jackpot),
    ...Object.values(mission),
    ...Object.values(auditLog),
    ...Object.values(dashboard),
    ...Object.values(tournament),
    ...Object.values(translation),
    ...Object.values(notification),
    ...Object.values(notifications),
]
/**
 * Root Epic
 * @param action$
 * @param store$
 * @param dependencies
 * @returns {Observable<ObservedValueOf<Observable<T>> | T>}
 */
const rootEpic = (action$, store$, dependencies) =>
    combineEpics(...epics)(action$, store$, dependencies).pipe(
        catchError((error, source) => {
            console.error(error)
            return source
        })
    )

export default rootEpic
