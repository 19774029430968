/**
 * Module Redux Feed Epics
 *
 * Author Matic Kogovšek [kogovsek.matic@gmail.com]
 */
/*!
 * Module dependencies.
 */
import { ofType } from 'redux-observable'
import { filter } from 'rxjs/operators'

import history from '../../history'

/**
 * Handle Unauthorised
 * @param action$
 * @param state$
 * @returns {*}
 */
export const handleAuthUnauthorised = (action$, state$) =>
    action$.pipe(
        ofType('AUTH_LOGIN_SUCCESS'),
        filter(() => history.push(state$.value?.view.lastPath || '/') && false)
    )
