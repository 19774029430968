/**
 * Module Redux Feed Epics
 */
/*!
 * Module dependencies.
 */
import { ofType } from 'redux-observable'
import { from, race, timer } from 'rxjs'
import { map, mapTo, switchMap, throttle } from 'rxjs/operators'

import { api } from '../../config/api'

/**
 * View Translation
 * @param action$
 * @returns {*}
 */
export const viewTranslations = (action$) => action$.pipe(ofType('VIEW_TRANSLATIONS'), mapTo({ type: 'TRANSLATION_FETCH' }))

/**
 * Fulfill fetch Translation
 * @param action$
 * @param state$
 * @returns {*}
 */
export const fetchTranslation = (action$) =>
    action$.pipe(
        ofType('TRANSLATION_FETCH'),
        throttle(() => race(action$.pipe(ofType('TRANSLATION_FETCH_FULFILLED')), timer(5000))),
        switchMap(({ payload }) => from(fetch(api + '/translation/translations', { credentials: 'include' }).then((res) => (res.status === 200 ? res.json() : null)))),
        map((res) => (res ? { type: 'TRANSLATION_FETCH_FULFILLED', payload: res } : { type: 'AUTH_UNAUTHORISED' }))
    )

/**
 * Fulfill delete Translation
 * @param action$
 * @param state$
 * @returns {*}
 */
export const deleteTranslation = (action$) =>
    action$.pipe(
        ofType('TRANSLATION_DELETE'),
        throttle(() => race(action$.pipe(ofType('TRANSLATION_DELETE_FULFILLED')), timer(5000))),
        switchMap(({ payload }) =>
            from(
                fetch(`${api}/translation/translation/${payload.id}/${payload.key}`, {
                    credentials: 'include',
                    method: 'DELETE',
                }).then((res) => (res.status === 200 ? res.json() : null))
            )
        ),
        map((res) => (res ? { type: 'TRANSLATION_DELETE_FULFILLED', payload: res } : { type: 'AUTH_UNAUTHORISED' }))
    )
