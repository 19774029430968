import { localStorageKey } from '../../config/store'

/**
 * Initial store state
 * @type {}
 */
const initialState = {
    schedule: [],
    scheduleIsFetching: false,
    scheduleSynced: new Date(0),

    instance: [],
    instanceIsFetching: false,
    instanceSynced: new Date(0),
}
/**
 * Local store
 * @type {Object}
 */
const localStore = JSON.parse(localStorage.getItem(localStorageKey) || '{}')
/**
 * Feed reducer
 * @param state
 * @param action
 * @returns {{edit: {}, saved: {}, error: {}, synced: boolean, list: Array}}
 */
const missionReducer = (state = localStore.mission ? { ...localStore.mission } : initialState, action) => {
    switch (action.type) {
        case 'MISSION_SCHEDULE_UPDATE':
            return {
                ...state,
                schedule: state.schedule.find((ms) => ms.id === action.payload.id)
                    ? state.schedule.map((ms) => (ms.id === action.payload.id ? action.payload : ms))
                    : [action.payload, ...state.schedule],
                scheduleSynced: new Date(),
            }
        case 'MISSION_INSTANCE_UPDATE':
            return {
                ...state,
                instance: state.instance.find((mi) => mi.id === action.payload.id)
                    ? state.instance.map((mi) => (mi.id === action.payload.id ? action.payload : mi))
                    : [action.payload, ...state.instance],
                instanceSynced: new Date(),
            }
        case 'MISSION_SCHEDULE_FETCH':
            return {
                ...state,
                scheduleIsFetching: true,
            }
        case 'MISSION_SCHEDULE_FETCH_FULFILLED':
            return {
                ...state,
                schedule: action.payload,
                scheduleIsFetching: false,
                scheduleSynced: new Date(),
            }
        case 'MISSION_INSTANCE_FETCH':
            return {
                ...state,
                instanceIsFetching: true,
            }
        case 'MISSION_INSTANCE_FETCH_FULFILLED':
            return {
                ...state,
                instance: action.payload,
                instanceIsFetching: false,
                instanceSynced: new Date(),
            }
        case 'MISSION_SCHEDULE_DELETE_FULFILLED':
            return {
                ...state,
                schedule: state.schedule.filter((ms) => ms.id !== action.payload.id),
                scheduleSynced: new Date(),
            }
        case 'MISSION_INSTANCE_DELETE_FULFILLED':
            return {
                ...state,
                instance: state.instance.filter((ms) => ms.id !== action.payload.id),
                instanceSynced: new Date(),
            }
        default:
            return state
    }
}

export default missionReducer
