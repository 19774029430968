/**
 * Module Redux Tournament Reducer
 *
 * Author Matic Kogovšek [kogovsek.matic@gmail.com]
 */
/*!
 * Module dependencies.
 */
import { localStorageKey } from '../../config/store'

/**
 * Initial store state
 * @type {}
 */
const initialState = {
    ts: new Date(0),
    failed: false,
    platforms: [],
    entities: {},
    role: '',
    email: '',
    redirectURL: '',
}
/**
 * Local store
 * @type {Object}
 */
const localStore = JSON.parse(localStorage.getItem(localStorageKey) || '{}')
/**
 * Dashboard reducer
 * @param state
 * @param action
 * @returns {{edit: {}, saved: {}, error: {}, synced: boolean, list: Array}}
 */
const dashboardReducer = (state = localStore.auth ? { ...localStore.auth } : initialState, action) => {
    switch (action.type) {
        case 'AUTH_LOGIN_FAILED':
            return {
                ...state,
                failed: true,
                role: '',
                email: '',
                auth_items: [],
                twoStepCreate: false,
                platforms: [],
                entities: {},
                ts: new Date(),
            }
        case 'AUTH_RESYNC_SUCCESS':
        case 'AUTH_LOGIN_SUCCESS':
            return {
                ...state,
                failed: false,
                role: action.payload.role,
                auth_items: action.payload.auth_items,
                twoStepCreate: action.payload.twoStepCreate,
                platforms: action.payload.platforms,
                entities: action.payload.entities,
                email: action.payload.email,
                ts: new Date(),
            }
        case 'AUTH_RESYNC_SAVE_REDIRECT_URL':
            return {
                ...state,
                redirectURL: action.payload,
                ts: new Date(),
            }
        case 'AUTH_RESYNC_CLEAR_REDIRECT_URL':
            return {
                ...state,
                redirectURL: '',
                ts: new Date(),
            }
        // moved to rootReducer since, logout needs to clear other states
        /*case "AUTH_LOGOUT_SUCCESS":
            return {
                ...state
                , failed: false
                , role: ''
                , entities: []
                , ts: new Date()
            };
            */
        default:
            return state
    }
}

export default dashboardReducer
