/**
 * Module Redux Dashboard Epics
 *
 * Author Matic Kogovšek [kogovsek.matic@gmail.com]
 */
/*!
 * Module dependencies.
 */
import { ofType } from 'redux-observable'
import { from, race, timer } from 'rxjs'
import { delay, map, mapTo /*, filter*/, switchMap, throttle } from 'rxjs/operators'

import { api } from '../../config/api'
import { utilitySync } from '../actions/util'

// state.util.[games|currencies|entities] require authentication so if user is not logged in
// when "UTILITY_SYNC" occures then fetch calls fail;
// so after users login he/she is redirected to /dashboard;
// now here first try to resync util values

/**
 * Handle state.util data resync after login
 * @param action$
 * @param state$
 * @returns {*}
 */

export const handleUtilEntitiesResync = (action$, state$) =>
    action$.pipe(
        ofType('VIEW_DASHBOARD'),
        /*, filter(() => (
        (state$.value?.util.games_synced.getTime() < Date.now() - 1000 * 60 * 5) ||
        (state$.value?.util.currencies_synced.getTime() < Date.now() - 1000 * 60 * 5) ||
        (state$.value?.util.entities_synced.getTime() < Date.now() - 1000 * 60 * 5)
    ))
    , mapTo({ type: 'RESYNC_UTIL_ENTITIES' })*/
        throttle(() => race(action$.pipe(ofType('VIEW_DASHBOARD')), timer(5000))),
        delay(1000),
        mapTo(utilitySync())
    )

/*export const fetchUtilEntitiesData = action$ => action$.pipe(
    ofType("RESYNC_UTIL_ENTITIES")
    , throttle(() => race(action$.pipe(ofType("RESYNC_UTIL_ENTITIES_FULFILLED")), timer(5000)))
    , switchMap(({payload}) => from(
        fetch(api + '/util/entities', {credentials: 'include'})
            .then(res => res.status === 200 ? res.json() : null)
    ))
    , map(res =>
        res
            ? {type: 'RESYNC_UTIL_ENTITIES_FULFILLED', payload: res} // utilityReducer 
            : {type: 'AUTH_UNAUTHORISED'}
    )
);*/

/**
 * Handle Dashboard Display of number of trnsactions per entity
 * @param action$
 * @param state$
 * @returns {*}
 */
// display num of trx per entity - on dashboard; atm not in use
/*
export const handleDashboardNumOfTrxPerEntityView = (action$, state$) => action$.pipe(
    ofType("VIEW_DASHBOARD_NUM_TRX_PER_ENTITY")
    //, filter(() => state$.value?.dashboard.trxPerEntitySynced.getTime() < Date.now() - 1000 * 60 * 5) //no need, controled by setInterval() in /view/dashboard
    , mapTo({ type: 'DASHBOARD_TRX_PER_ENTITY_DATA_FETCH' })
);
*/

/**
 * Fulfill fetch Dashboard Data fetch - number of transactions per entity
 * @param action$
 * @param state$
 * @returns {*}
 */
/*
 export const fetchDashboardNumOfTrxPerEntityData = action$ => action$.pipe(
    ofType("DASHBOARD_TRX_PER_ENTITY_DATA_FETCH")
    //, throttle(() => race(action$.pipe(ofType("DASHBOARD_TRX_PER_ENTITY_DATA_FETCH_FULFILLED")), timer(5000))) //no need, controled by setInterval() in /view/dashboard
    , switchMap(({payload}) => from(

        fetch(api + '/report/trx_cnt', {method: 'GET'
        ,   headers: {'Content-Type': 'application/json'}
        ,   credentials: 'include'
        })
        .then(res => {
            if(res.ok) {
                return res;
            } else {
                //throw Error(`Request rejected with status ${res.status}`);
                return null;
            }
        })
        .then(res => res !== null ? res.json() : null)
        .catch( err => ( {"error": JSON.stringify(err)} ))
    ))
    , map(res =>
        res
            ? {type: 'DASHBOARD_TRX_PER_ENTITY_DATA_FETCH_FULFILLED', payload: res}
            : {type: 'AUTH_UNAUTHORISED'}
    )
);
*/

/**
 * Handle Dashboard Display of number of trnsactions per entity
 * @param action$
 * @param state$
 * @returns {*}
 */
export const handleDashboardGameTypeData = (action$, state$) => action$.pipe(ofType('VIEW_DASHBOARD_GAME_TYPE_DATA'), mapTo({ type: 'VIEW_DASHBOARD_GAME_TYPE_DATA_FETCH' }))

/**
 * Fulfill fetch Dashboard Data fetch - data about game types
 * @param action$
 * @param state$
 * @returns {*}
 */
export const fetchDashboardGameTypeData = (action$) =>
    action$.pipe(
        ofType('VIEW_DASHBOARD_GAME_TYPE_DATA_FETCH'),
        /*, throttle(() => race(action$.pipe(ofType("VIEW_DASHBOARD_GAME_TYPE_DATA_FETCH")), timer(5000)))*/ //no need, controled by setInterval() in /view/dashboard
        switchMap(({ payload }) =>
            from(
                fetch(api + '/report/dashboardGameTypeData', { method: 'GET', headers: { 'Content-Type': 'application/json' }, credentials: 'include' })
                    .then((res) => {
                        if (res.ok) {
                            return res
                        } else {
                            //throw Error(`Request rejected with status ${res.status}`);
                            return null
                        }
                    })
                    .then((res) => (res !== null ? res.json() : null))
                    .catch((err) => ({ error: JSON.stringify(err) }))
            )
        ),
        map((res) => (res ? { type: 'DASHBOARD_GAME_TYPE_DATA_FETCH_FULFILLED', payload: res } : { type: 'AUTH_UNAUTHORISED' }))
    )
