/**
 * Module Redux Audit Log Reducer
 */

/*!
 * Module dependencies.
 */
import { localStorageKey } from '../../config/store'

/**
 * Initial store state
 * @type {}
 */
const initialState = {
    auditLogs: [],
    isFetching: false,
    synced: new Date(0),
}
/**
 * Local store
 * @type {Object}
 */
const localStore = JSON.parse(localStorage.getItem(localStorageKey) || '{}')
/**
 * Feed reducer
 * @param state
 * @param action
 * @returns {{edit: {}, saved: {}, error: {}, synced: boolean, list: Array}}
 */
const auditLogReducer = (state = localStore.auditLog ? { ...localStore.auditLog } : initialState, action) => {
    switch (action.type) {
        case 'AUDIT_LOG_FETCH':
            return {
                ...state,
                isFetching: true,
            }

        case 'AUDIT_LOG_FETCH_FULFILLED':
            return {
                ...state,
                auditLogs: action.payload,
                isFetching: false,
                synced: new Date(),
            }

        default:
            return state
    }
}

export default auditLogReducer
