/**
 * Module Redux Util Epics
 *
 * Author Matic Kogovšek [kogovsek.matic@gmail.com]
 */
/*!
 * Module dependencies.
 */
import { ofType } from 'redux-observable'
import { from, race, timer } from 'rxjs'
import { filter, map, mapTo, switchMap, throttle } from 'rxjs/operators'

import { api } from '../../config/api'
import { fetchUtilityCurrency, fetchUtilityEntity, fetchUtilityEntityGroup, fetchUtilityGame, fetchUtilityPlatform, fetchUtilityWalletGroups } from '../actions/util'

// for this resp. statused redirect to login page
const UNAUTH_STATUSES = [
    401 /* Unauthorized */, 403 /* Access to that resource is forbidden */, 405 /* Method not allowed */, 407 /* Proxy Authentication Required */, 429 /* Too many requests */,
    511 /* Network Authentication Required */,
]

const processRespBasedOnRespStatus = (res) => {
    if (res.ok) {
        //(res.status >= 200 && res.status <= 299)
        return res.json()
    } else if (UNAUTH_STATUSES.indexOf(parseInt(res.status)) >= 0) {
        return parseInt(res.status)
    } else {
        return -1
    }
}

const processMapResp = (res, utilFunctName) => {
    const resToInt = parseInt(res)
    if (resToInt + '' === 'NaN') {
        /* parsing json always return NaN */
        return { type: `${utilFunctName}_FULFILLED`, payload: res }
    } else if (UNAUTH_STATUSES.indexOf(parseInt(res)) >= 0) {
        return { type: 'AUTH_UNAUTHORISED' }
    } else {
        return { type: `${utilFunctName}_NET_PREOBLEM` }
    }
}

/**
 * Fetch Platforms check
 * @param action$
 * @param state$
 * @returns {*}
 */
export const checkPlatformsSync = (action$, state$) =>
    action$.pipe(
        ofType('UTILITY_SYNC'),
        filter(
            () => state$.value.util?.platforms.length === 0 || new Date(state$.value.util.platforms_synced).getTime() < Date.now() - 1000 * 60 * 60 * 6 || !state$.value.util?.platforms_res_succeeded
        ),
        mapTo(fetchUtilityPlatform())
    )

/**
 * Fulfill fetch Utility Platforms
 * @param action$
 * @param state$
 * @returns {*}
 */
export const fetchUtilityPlatforms = (action$) =>
    action$.pipe(
        ofType('UTILITY_PLATFORM_FETCH'),
        throttle(() => race(action$.pipe(ofType('UTILITY_PLATFORM_FETCH_FULFILLED')), timer(2000))),
        switchMap(({ payload }) =>
            from(
                fetch(api + '/util/platforms', { credentials: 'include' })
                    .then((res) => processRespBasedOnRespStatus(res))
                    .catch(function (err) {
                        // handling possible network errors
                        return -1
                    })
            )
        ),
        map((res) => processMapResp(res, 'UTILITY_PLATFORM_FETCH'))
    )

/**
 * Fetch Currencies check
 * @param action$
 * @param state$
 * @returns {*}
 */
export const checCurrenciesSync = (action$, state$) =>
    action$.pipe(
        ofType('UTILITY_SYNC'),
        filter(
            () =>
                state$.value.util?.currencies.length === 0 || new Date(state$.value.util.currencies_synced).getTime() < Date.now() - 1000 * 60 * 60 * 6 || !state$.value.util?.currencies_res_succeeded
        ),
        mapTo(fetchUtilityCurrency())
    )

/**
 * Fulfill fetch Utility Currencies
 * @param action$
 * @param state$
 * @returns {*}
 */
export const fetchUtilityCurrencies = (action$) =>
    action$.pipe(
        ofType('UTILITY_CURRENCY_FETCH'),
        throttle(() => race(action$.pipe(ofType('UTILITY_CURRENCY_FETCH_FULFILLED')), timer(2000))),
        switchMap(({ payload }) =>
            from(
                fetch(api + '/util/currencies', { credentials: 'include' })
                    .then((res) => processRespBasedOnRespStatus(res))
                    .catch(function (err) {
                        // handling possible network errors
                        return -1
                    })
            )
        ),
        map((res) => processMapResp(res, 'UTILITY_CURRENCY_FETCH'))
    )

/**
 * Fetch Games check
 * @param action$
 * @param state$
 * @returns {*}
 */
export const checkGameSync = (action$, state$) =>
    action$.pipe(
        ofType('UTILITY_SYNC'),
        filter(() => state$.value.util?.games.length === 0 || new Date(state$.value.util?.games_synced).getTime() < Date.now() - 1000 * 60 * 60 * 6 || !state$.value.util?.games_res_succeeded),
        mapTo(fetchUtilityGame())
    )

/**
 * Fulfill fetch Utility Games
 * @param action$
 * @param state$
 * @returns {*}
 */
export const fetchUtilityGames = (action$) =>
    action$.pipe(
        ofType('UTILITY_GAME_FETCH'),
        throttle(() => race(action$.pipe(ofType('UTILITY_GAME_FETCH_FULFILLED')), timer(2000))),
        switchMap(({ payload }) =>
            from(
                fetch(api + '/util/games', { credentials: 'include' })
                    .then((res) => processRespBasedOnRespStatus(res))
                    .catch(function (err) {
                        // handling possible network errors
                        return -1
                    })
            )
        ),
        map((res) => processMapResp(res, 'UTILITY_GAME_FETCH'))
    )

/**
 * Fetch Entities check
 * @param action$
 * @param state$
 * @returns {*}
 */
export const checEntitiesSync = (action$, state$) =>
    action$.pipe(
        ofType('UTILITY_SYNC'),
        filter(() => state$.value.util?.entities.length === 0 || new Date(state$.value.util.entities_synced).getTime() < Date.now() - 1000 * 60 * 60 * 6 || !state$.value.util?.entities_res_succeeded),
        mapTo(fetchUtilityEntity())
    )

/**
 * Fulfill fetch Utility Entities
 * @param action$
 * @param state$
 * @returns {*}
 */
export const fetchUtilityEntities = (action$) =>
    action$.pipe(
        ofType('UTILITY_ENTITY_FETCH'),
        throttle(() => race(action$.pipe(ofType('UTILITY_ENTITY_FETCH_FULFILLED')), timer(2000))),
        switchMap(({ payload }) =>
            from(
                fetch(api + '/util/entities', { credentials: 'include' })
                    .then((res) => processRespBasedOnRespStatus(res))
                    .catch(function (err) {
                        // handling possible network errors
                        return -1
                    })
            )
        ),
        map((res) => processMapResp(res, 'UTILITY_ENTITY_FETCH'))
    )

/**
 * Fetch Wallet Group check
 * @param action$
 * @param state$
 * @returns {*}
 */
export const checWalletGroupSync = (action$, state$) =>
    action$.pipe(
        ofType('UTILITY_SYNC'),
        filter(
            () =>
                state$.value.util?.walletGroups.length === 0 ||
                new Date(state$.value.util.walletGroups_synced).getTime() < Date.now() - 1000 * 60 * 60 * 6 ||
                !state$.value.util?.walletGroups_res_succeeded
        ),
        mapTo(fetchUtilityWalletGroups())
    )

/**
 * Fulfill fetch Utility Wallet Groups
 * @param action$
 * @param state$
 * @returns {*}
 */
export const fetchWalletGroups = (action$) =>
    action$.pipe(
        ofType('UTILITY_WALLET_GROUP_FETCH'),
        throttle(() => race(action$.pipe(ofType('UTILITY_WALLET_GROUP_FETCH_FULFILLED')), timer(2000))),
        switchMap(({ payload }) =>
            from(
                fetch(api + '/util/wallet/groups', { credentials: 'include' })
                    .then((res) => processRespBasedOnRespStatus(res))
                    .catch(function (err) {
                        // handling possible network errors
                        return -1
                    })
            )
        ),
        map((res) => processMapResp(res, 'UTILITY_WALLET_GROUP_FETCH'))
    )

/**
 * Fulfill fetch Utility game google sheet (GameTags)
 * @param action$
 * @param state$
 * @returns {*}
 */
export const fetchUtilityGameGSheet = (action$) =>
    action$.pipe(
        ofType('UTILITY_GAME_GSHEET_FETCH'),
        throttle(() => race(action$.pipe(ofType('UTILITY_GAME_GSHEET_FETCH_FULFILLED')), timer(30000))),
        switchMap(({ payload }) =>
            from(
                fetch(api + '/util/gamesMoreInfo', { credentials: 'include' })
                    .then((res) => processRespBasedOnRespStatus(res))
                    .catch(function (err) {
                        // handling possible network errors
                        return -1
                    })
            )
        ),
        map((res) => processMapResp(res, 'UTILITY_GAME_GSHEET_FETCH'))
    )

/**
 * Fetch Entitiy-Groups check
 * @param action$
 * @param state$
 * @returns {*}
 */
export const checEntityGroupsSync = (action$, state$) =>
    action$.pipe(
        ofType('UTILITY_SYNC'),
        filter(
            () =>
                state$.value.util?.entityGroups.length === 0 ||
                new Date(state$.value.util.entityGroups_synced).getTime() < Date.now() - 1000 * 60 * 60 * 6 ||
                !state$.value.util?.entityGroups_res_succeeded
        ),
        mapTo(fetchUtilityEntityGroup())
    )

/**
 * Fulfill fetch Utility Entitiy-Groups
 * @param action$
 * @param state$
 * @returns {*}
 */
export const fetchUtilityEntityGroups = (action$) =>
    action$.pipe(
        ofType('UTILITY_ENTITY_GROUPS_FETCH'),
        throttle(() => race(action$.pipe(ofType('UTILITY_ENTITY_GROUP_FETCH_FULFILLED')), timer(2000))),
        switchMap(({ payload }) =>
            from(
                fetch(api + '/util/entityGroups', { credentials: 'include' })
                    .then((res) => processRespBasedOnRespStatus(res))
                    .catch(function (err) {
                        // handling possible network errors
                        return -1
                    })
            )
        ),
        map((res) => processMapResp(res, 'UTILITY_ENTITY_GROUP_FETCH'))
    )

/**
 * Fulfill delete Utility Entitiy-Groups
 * @param action$
 * @param state$
 * @returns {*}
 */
export const deleteUtilityEntityGroup = (action$) =>
    action$.pipe(
        ofType('UTILITY_ENTITY_GROUP_DELETE'),
        throttle(() => race(action$.pipe(ofType('UTILITY_ENTITY_GROUP_DELETE_FULFILLED')), timer(5000))),
        switchMap(({ payload }) => from(fetch(api + '/util/entityGroups/' + payload.id, { credentials: 'include', method: 'DELETE' }).then((res) => (res.status === 200 ? res.json() : null)))),
        map((res) => (res ? { type: 'UTILITY_ENTITY_GROUP_DELETE_FULFILLED', payload: res } : { type: 'UTILITY_ENTITY_GROUP_DO_NOTHING' }))
    )

/**
 * Update/create Utility Entitiy-Groups
 * @param action$
 * @param state$
 * @returns {*}
 */
export const updateUtilityEntityGroup = (action$) =>
    action$.pipe(
        ofType('UTILITY_ENTITY_GROUP_UPDATE'),
        throttle(() => race(action$.pipe(ofType('UTILITY_ENTITY_GROUP_UPDATE_FULFILLED')), timer(5000))),
        switchMap(({ payload }) =>
            from(
                fetch(api + '/util/entityGroups', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include',
                    body: JSON.stringify(payload),
                }).then((res) => (res.status === 200 ? res.json() : null))
            )
        ),
        map((res) => (res ? { type: 'UTILITY_ENTITY_GROUP_UPDATE_FULFILLED', payload: res } : { type: 'UTILITY_ENTITY_GROUP_DO_NOTHING' }))
    )
