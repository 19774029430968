import React from 'react'

import packageJson from '../package.json'

global.appVersion = packageJson.version

// src: https://github.com/flexdinesh/cache-busting-example

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g)

    const versionsB = versionB.split(/\./g)
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift())

        const b = Number(versionsB.shift())
        // eslint-disable-next-line no-continue
        if (a === b) continue
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b)
    }
    return false
}

class CacheBuster extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            isLatestVersion: false,
            refreshCacheAndReload: () => {
                console.log('Clearing cache and hard reloading...')

                if (caches) {
                    // Service worker cache should be cleared with caches.delete()
                    //caches.keys().then(function(names) {
                    //    for (let name of names) caches.delete(name);
                    //});

                    // from comments it seems that it's better to awaic caches clearing and then do page reload
                    caches.keys().then(async function (names) {
                        await Promise.all(names.map((name) => caches.delete(name)))
                    })
                }

                // delete browser cache and hard reload
                window.location.reload() //https://stackoverflow.com/questions/2405117/difference-between-window-location-href-window-location-href-and-window-location
            },
        }
    }

    componentDidMount() {
        fetch('/meta.json?ts=' + new Date().getTime(), {
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion = meta.version
                const currentVersion = global.appVersion

                const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion)
                if (shouldForceRefresh) {
                    console.log(`We have a new version - ${latestVersion}. Should force refresh`)
                    this.setState({ loading: false, isLatestVersion: false })
                } else {
                    console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`)
                    this.setState({ loading: false, isLatestVersion: true })
                }
            })
            .catch((error) => {
                console.warn('The `meta.json` file does not exist on the server site. Please get in touch with the site mantainers.')
                //const latestVersion = {"version":"1.0.0"};
                this.setState({ loading: false, isLatestVersion: false })
            })
    }
    render() {
        const { loading, isLatestVersion, refreshCacheAndReload } = this.state
        return this.props.children({ loading, isLatestVersion, refreshCacheAndReload })
    }
}

export default CacheBuster
