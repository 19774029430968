/**
 * Module Redux Util Actions
 *
 * Author Matic Kogovšek [kogovsek.matic@gmail.com]
 */
/*!
 * Module dependencies.
 */

/**
 * Fetch Util Games action
 * @returns {{type: string, payload: {}}}
 */
export const utilitySync = (payload = {}) => ({ type: 'UTILITY_SYNC', payload })

/**
 * Fetch Util Games action
 * @returns {{type: string, payload: {}}}
 */
export const fetchUtilityGame = (payload = {}) => ({ type: 'UTILITY_GAME_FETCH', payload })

/**
 * Fetch Util Currencies action
 * @returns {{type: string, payload: {}}}
 */
export const fetchUtilityCurrency = (payload = {}) => ({ type: 'UTILITY_CURRENCY_FETCH', payload })

/**
 * Fetch Util Entity action
 * @returns {{type: string, payload: {}}}
 */
export const fetchUtilityEntity = (payload = {}) => ({ type: 'UTILITY_ENTITY_FETCH', payload })

/**
 * Fetch Util Wallet Groups action
 * @returns {{type: string, payload: {}}}
 */
export const fetchUtilityWalletGroups = (payload = {}) => ({ type: 'UTILITY_WALLET_GROUP_FETCH', payload })

/**
 * Fetch google spreadsheet (Game-Tags) action
 * @returns {{type: string, payload: {}}}
 */
export const fetchUtilityGameGSheet = (payload = {}) => ({ type: 'UTILITY_GAME_GSHEET_FETCH', payload })

/**
 * Fetch Util Entity-Groups action
 * @returns {{type: string, payload: {}}}
 */
export const fetchUtilityEntityGroup = (payload = {}) => ({ type: 'UTILITY_ENTITY_GROUPS_FETCH', payload })

/**
 * Delate Util Entity-Group action
 * @returns {{type: string, payload: {}}}
 */
export const utilityEntityGroupDelete = (payload = {}) => ({ type: 'UTILITY_ENTITY_GROUP_DELETE', payload })

/**
 * Update Util Entity-Group action
 * @returns {{type: string, payload: {}}}
 */
export const updateUtilityEntityGroup = (payload = {}) => ({ type: 'UTILITY_ENTITY_GROUP_UPDATE', payload })

/**
 * Fetch Util Platforms action
 * @returns {{type: string, payload: {}}}
 */
export const fetchUtilityPlatform = (payload = {}) => ({ type: 'UTILITY_PLATFORM_FETCH', payload })
