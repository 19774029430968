/**
 * Module Redux Feed Epics
 */
/*!
 * Module dependencies.
 */
import { ofType } from 'redux-observable'
import { from, race, timer } from 'rxjs'
import { map, mapTo, switchMap, throttle } from 'rxjs/operators'

import { api } from '../../config/api'

/**
 * View Audit Log
 * @param action$
 * @returns {*}
 */
export const viewAuditLogs = (action$) => action$.pipe(ofType('VIEW_AUDIT_LOGS'), mapTo({ type: 'AUDIT_LOG_FETCH' }))

/**
 * Fulfill fetch Audit Log
 * @param action$
 * @param state$
 * @returns {*}
 */
export const fetchAuditLog = (action$) =>
    action$.pipe(
        ofType('AUDIT_LOG_FETCH'),
        throttle(() => race(action$.pipe(ofType('AUDIT_LOG_FETCH_FULFILLED')), timer(5000))),
        switchMap(({ payload }) => from(fetch(api + '/system/audit-logs', { credentials: 'include' }).then((res) => (res.status === 200 ? res.json() : null)))),
        map((res) => (res ? { type: 'AUDIT_LOG_FETCH_FULFILLED', payload: res } : { type: 'AUTH_UNAUTHORISED' }))
    )
