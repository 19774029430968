export const sygnet = [
    '172, 172',
    `
  <title>coreui logo</title>
  <g xmlns="http://www.w3.org/2000/svg">
		<path fill="#6FCCDD" d="M33.81,137.45c0.24,0.41,0.44,0.5,0.48,0.5c0.04,0,0.25-0.09,0.48-0.51l6.33-11.29    c0.3-0.53,0.47-1.31,0.47-2.12c0-0.84-0.18-1.63-0.49-2.18L21.34,87.53c-0.52-0.91-0.81-2.1-0.81-3.35c0-1.27,0.3-2.49,0.85-3.41    l19.68-33.47c0.31-0.55,0.5-1.33,0.5-2.16c0-0.8-0.17-1.56-0.47-2.09l-6.3-11.08c-0.24-0.42-0.45-0.51-0.49-0.51    c-0.04,0-0.25,0.09-0.49,0.5L4.37,82.63c-0.31,0.53-0.49,1.3-0.49,2.12c0,0.81,0.18,1.58,0.49,2.12L33.81,137.45z"/>
		<path fill="#6FCCDD" d="M136.61,31.05c-0.22-0.37-0.44-0.51-0.52-0.51c-0.08,0-0.3,0.14-0.51,0.52l-6.28,10.98    c-0.3,0.53-0.47,1.28-0.47,2.08c0,0.83,0.19,1.61,0.51,2.16l20.45,34.5c0.55,0.92,0.85,2.13,0.85,3.39c0,1.25-0.3,2.44-0.83,3.36    l-20.58,35.15c-0.32,0.54-0.51,1.33-0.51,2.17c0,0.79,0.17,1.55,0.47,2.08l6.41,11.31c0.22,0.38,0.43,0.51,0.5,0.51    c0.07,0,0.28-0.12,0.51-0.5l30.49-51.73c0.31-0.52,0.49-1.29,0.49-2.09c0-0.8-0.18-1.57-0.5-2.1L136.61,31.05z"/>
		<path class="slidecaption" fill="#6FCCDD" d="M127.12,154.1l1.27-2.17c0.32-0.54,0.5-1.34,0.5-2.17c0-0.79-0.17-1.56-0.47-2.08L62.91,31.73    c-0.35-0.61-0.53-1.23-0.53-1.83c0-0.84,0.35-1.59,0.99-2.1c0.58-0.47,1.35-0.72,2.25-0.72h38.43c1.9,0,3.23,1.16,3.23,2.81    c0,0.6-0.17,1.23-0.53,1.84L92.16,57.32c-0.31,0.54-0.49,1.31-0.49,2.13c0,0.82,0.18,1.6,0.49,2.14l6.49,11.33    c0.22,0.38,0.43,0.51,0.49,0.51c0.06,0,0.28-0.13,0.49-0.51l28.83-50.4c0.31-0.52,0.48-1.28,0.48-2.07c0-0.83-0.19-1.61-0.51-2.15    l-1.87-3.17c-0.67-1.13-2.41-2.12-3.72-2.12H46.08c-1.3,0-3,1-3.65,2.13l-1.11,1.96c-0.31,0.54-0.48,1.32-0.48,2.15    c0,0.82,0.18,1.6,0.48,2.14l65.66,115.94c0.35,0.6,0.53,1.22,0.53,1.82c0,1.64-1.33,2.8-3.23,2.8H65.28c-1.9,0-3.23-1.15-3.23-2.8    c0-0.61,0.18-1.22,0.53-1.84l14.6-25.65c0.3-0.54,0.48-1.32,0.48-2.13c0-0.82-0.18-1.6-0.49-2.14l-6.15-10.75    c-0.22-0.38-0.43-0.5-0.49-0.5c-0.06,0-0.27,0.13-0.48,0.5l-29,50.77c-0.3,0.53-0.48,1.29-0.48,2.08l0,0    c0,0.82,0.18,1.61,0.51,2.15l1.46,2.47c0.67,1.12,2.42,2.12,3.73,2.12h77.16C124.73,156.23,126.46,155.23,127.12,154.1z"/>
	</g>
`,
]
