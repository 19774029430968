/*!
 * Module dependencies.
 */
import { localStorageKey } from '../../config/store'

/**
 * Initial store state
 * @type {}
 */
const initialState = {
    telegramChats: [],
    telegramChatsIsFetching: false,
    telegramChatsSynced: new Date(0),
}
/**
 * Local store
 * @type {Object}
 */
const localStore = JSON.parse(localStorage.getItem(localStorageKey) || '{}')

const notificationsReducer = (state = localStore.notifications ? { ...localStore.notifications } : initialState, action) => {
    switch (action.type) {
        case 'TELEGRAM_CHATS_FETCH':
            return {
                ...state,
                telegramChatsIsFetching: true,
            }
        case 'TELEGRAM_CHATS_FETCH_FULFILLED':
            return {
                ...state,
                telegramChats: action.payload,
                telegramChatsIsFetching: false,
                telegramChatsSynced: new Date(),
            }
        case 'NOTIFICATIONS_TELEGRAM_CHAT_UPDATE':
            return {
                ...state,
                telegramChats: state.telegramChats.find((tc) => tc['_id'] === action.payload['_id'])
                    ? state.telegramChats.map((tc) => (tc['_id'] === action.payload['_id'] ? action.payload : tc))
                    : [action.payload, ...state.telegramChats],
                telegramChatsSynced: new Date(),
            }
        case 'NOTIFICATIONS_TELEGRAM_CHAT_DELETE_FULFILLED':
            return {
                ...state,
                telegramChats: state.telegramChats.filter((tc) => tc['_id'] !== action.payload['_id']),
            }
        default:
            return state
    }
}

export default notificationsReducer
