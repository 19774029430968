/**
 * Module Redux Audit Log Reducer
 */

/*!
 * Module dependencies.
 */
import { localStorageKey } from '../../config/store'

/**
 * Initial store state
 * @type {}
 */
const initialState = {
    apiLogs: [],
    isFetching: false,
    synced: new Date(0),
}
/**
 * Local store
 * @type {Object}
 */
const localStore = JSON.parse(localStorage.getItem(localStorageKey) || '{}')
/**
 * Feed reducer
 * @param state
 * @param action
 */
const apiLogReducer = (state = localStore.auditLog ? { ...localStore.auditLog } : initialState, action) => {
    switch (action.type) {
        case 'API_LOGS_FETCH':
            return {
                ...state,
                isFetching: true,
            }

        case 'API_LOGS_FETCH_FULFILLED':
            return {
                ...state,
                apiLogs: action.payload,
                isFetching: false,
                synced: new Date(),
            }

        default:
            return state
    }
}

export default apiLogReducer
