/*!
 * Module dependencies.
 */

// meant for actions from `/notifications` folder

import { ofType } from 'redux-observable'
import { from, race, timer } from 'rxjs'
import { map, mapTo, switchMap, throttle } from 'rxjs/operators'

import { api } from '../../config/api'

/**
 * View Notifications -> Telegam chats
 * @param action$
 * @returns {*}
 */
export const viewTelegramChats = (action$) => action$.pipe(ofType('VIEW_TELEGRAM_CHATS'), mapTo({ type: 'TELEGRAM_CHATS_FETCH' }))

/**
 * Fulfill fetch Notifications -> Telegam chats
 * @param action$
 * @param state$
 * @returns {*}
 */
export const fetchTelegramChats = (action$) =>
    action$.pipe(
        ofType('TELEGRAM_CHATS_FETCH'),
        throttle(() => race(action$.pipe(ofType('TELEGRAM_CHATS_FETCH_FULFILLED')), timer(5000))),
        switchMap(({ payload }) => from(fetch(api + '/notifications/telegram/chats', { credentials: 'include' }).then((res) => (res.status === 200 ? res.json() : null)))),
        map((res) => (res ? { type: 'TELEGRAM_CHATS_FETCH_FULFILLED', payload: res } : { type: 'AUTH_UNAUTHORISED' }))
    )

/**
 * Fulfill delete telegram chat
 * @param action$
 * @param state$
 * @returns {*}
 */
export const deleteTelegramChat = (action$) =>
    action$.pipe(
        ofType('NOTIFICATIONS_TELEGRAM_CHAT_DELETE'),
        throttle(() => race(action$.pipe(ofType('NOTIFICATIONS_TELEGRAM_CHAT_DELETE_FULFILLED')), timer(5000))),
        switchMap(({ payload }) =>
            from(fetch(api + '/notifications/telegram/chat/' + payload.id, { credentials: 'include', method: 'DELETE' }).then((res) => (res.status === 200 ? res.json() : null)))
        ),
        map((res) => (res ? { type: 'NOTIFICATIONS_TELEGRAM_CHAT_DELETE_FULFILLED', payload: res } : { type: 'AUTH_UNAUTHORISED' }))
    )
