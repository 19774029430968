/**
 * Module Redux Util Epics
 *
 * Author Matic Kogovšek [kogovsek.matic@gmail.com]
 */
/*!
 * Module dependencies.
 */
import { ofType } from 'redux-observable'
import { from, race, timer } from 'rxjs'
import { map, mapTo, switchMap, throttle } from 'rxjs/operators'

import { api } from '../../config/api'

/**
 * View System Users
 * @param action$
 * @returns {*}
 */
export const viewSystemUserse = (action$) => action$.pipe(ofType('VIEW_SYSTEM_USERS'), mapTo({ type: 'SYSTEM_USER_FETCH' }))

/**
 * Fulfill fetch Utility Games
 * @param action$
 * @param state$
 * @returns {*}
 */
export const fetchUtilityGames = (action$) =>
    action$.pipe(
        ofType('SYSTEM_USER_FETCH'),
        throttle(() => race(action$.pipe(ofType('SYSTEM_USER_FETCH_FULFILLED')), timer(5000))),
        switchMap(({ payload }) => from(fetch(api + '/system/users', { credentials: 'include' }).then((res) => (res.status === 200 ? res.json() : null)))),
        map((res) => (res ? { type: 'SYSTEM_USER_FETCH_FULFILLED', payload: res } : { type: 'AUTH_UNAUTHORISED' }))
    )

/**
 * Fulfill delete System User
 * @param action$
 * @param state$
 * @returns {*}
 */
export const deleteSystemUser = (action$) =>
    action$.pipe(
        ofType('SYSTEM_USER_DELETE'),
        throttle(() => race(action$.pipe(ofType('SYSTEM_USER_DELETE_FULFILLED')), timer(5000))),
        switchMap(({ payload }) => from(fetch(api + '/system/user/' + payload.id, { credentials: 'include', method: 'DELETE' }).then((res) => (res.status === 200 ? res.json() : null)))),
        map((res) => (res ? { type: 'SYSTEM_USER_DELETE_FULFILLED', payload: res } : { type: 'AUTH_UNAUTHORISED' }))
    )

/**
 * View System Message Hooks
 * @param action$
 * @returns {*}
 */
export const viewSystemMessageHooks = (action$) => action$.pipe(ofType('VIEW_SYSTEM_MESSAGE_HOOKS'), mapTo({ type: 'SYSTEM_MESSAGE_HOOKS_FETCH' }))

/**
 * Fulfill fetch System Message Hooks
 * @param action$
 * @param state$
 * @returns {*}
 */
export const fetchSystemMsgHooks = (action$) =>
    action$.pipe(
        ofType('SYSTEM_MESSAGE_HOOKS_FETCH'),
        throttle(() => race(action$.pipe(ofType('SYSTEM_MESSAGE_HOOKS_FETCH_FULFILLED')), timer(3000))),
        switchMap(({ payload }) => from(fetch(api + '/system/messageHooks', { credentials: 'include' }).then((res) => (res.status === 200 ? res.json() : null)))),
        map((res) => (res ? { type: 'SYSTEM_MESSAGE_HOOKS_FETCH_FULFILLED', payload: res } : { type: 'AUTH_UNAUTHORISED' }))
    )

/**
 * Fulfill delete System Message Hook
 * @param action$
 * @param state$
 * @returns {*}
 */
export const deleteSystemMessageHook = (action$) =>
    action$.pipe(
        ofType('SYSTEM_MESSAGE_HOOK_DELETE'),
        throttle(() => race(action$.pipe(ofType('SYSTEM_MESSAGE_HOOK_DELETE_FULFILLED')), timer(5000))),
        switchMap(({ payload }) => from(fetch(api + '/system/messageHooks/' + payload.id, { credentials: 'include', method: 'DELETE' }).then((res) => (res.status === 200 ? res.json() : null)))),
        map((res) => (res ? { type: 'SYSTEM_MESSAGE_HOOK_DELETE_FULFILLED', payload: res } : { type: 'AUTH_UNAUTHORISED' }))
    )
