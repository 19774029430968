import './scss/style.scss'

import React, { Component } from 'react'
import { Route, Router, Switch } from 'react-router-dom'

import CacheBuster from './CacheBuster'
import history from './history'

const loadingAnimation = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
    render() {
        return (
            <CacheBuster>
                {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                    if (loading) return null
                    if (!loading && !isLatestVersion) {
                        if (window.confirm('It seems that you are using the old site version. Do you want to refresh the page and use the latest site?') === true) {
                            refreshCacheAndReload()
                        }
                    }
                    return (
                        <Router history={history}>
                            <React.Suspense fallback={loadingAnimation}>
                                <Switch>
                                    <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
                                    <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
                                    <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
                                    <Route path="/" name="Home" render={(props) => <TheLayout {...props} />} />
                                </Switch>
                            </React.Suspense>
                        </Router>
                    )
                }}
            </CacheBuster>
        )
    }
}

export default App
