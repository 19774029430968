/**
 * Module Redux Feed Epics
 *
 * Author Matic Kogovšek [kogovsek.matic@gmail.com]
 */
/*!
 * Module dependencies.
 */
import { ofType } from 'redux-observable'
import { from, race, timer } from 'rxjs'
import { map, mapTo, switchMap, throttle } from 'rxjs/operators'

import { api } from '../../config/api'

/**
 * View Tournament Schedule
 * @param action$
 * @returns {*}
 */
export const viewTournamentSchedule = (action$) => action$.pipe(ofType('VIEW_TOURNAMENT_SCHEDULE'), mapTo({ type: 'TOURNAMENT_SCHEDULE_FETCH' }))

/**
 * Fulfill fetch Tournament Schedule
 * @param action$
 * @param state$
 * @returns {*}
 */
export const fetchTournamentSchedule = (action$) =>
    action$.pipe(
        ofType('TOURNAMENT_SCHEDULE_FETCH'),
        throttle(() => race(action$.pipe(ofType('TOURNAMENT_SCHEDULE_FETCH_FULFILLED')), timer(5000))),
        switchMap(({ payload }) => from(fetch(api + '/tournament/schedules', { credentials: 'include' }).then((res) => (res.status === 200 ? res.json() : null)))),
        map((res) => (res ? { type: 'TOURNAMENT_SCHEDULE_FETCH_FULFILLED', payload: res } : { type: 'AUTH_UNAUTHORISED' }))
    )

/**
 * Fulfill delete Tournament Schedule
 * @param action$
 * @param state$
 * @returns {*}
 */
export const deleteTournamentSchedule = (action$) =>
    action$.pipe(
        ofType('TOURNAMENT_SCHEDULE_DELETE'),
        throttle(() => race(action$.pipe(ofType('TOURNAMENT_SCHEDULE_DELETE_FULFILLED')), timer(5000))),
        switchMap(({ payload }) => from(fetch(api + '/tournament/schedule/' + payload.id, { credentials: 'include', method: 'DELETE' }).then((res) => (res.status === 200 ? res.json() : null)))),
        map((res) => (res ? { type: 'TOURNAMENT_SCHEDULE_DELETE_FULFILLED', payload: res } : { type: 'AUTH_UNAUTHORISED' }))
    )

/**
 * View Tournament Instance
 * @param action$
 * @returns {*}
 */
export const viewTournamentInstance = (action$) =>
    action$.pipe(
        ofType('VIEW_TOURNAMENT_INSTANCE'),
        map(({ payload }) => ({ type: 'TOURNAMENT_INSTANCE_FETCH', payload: payload }))
    )

/**
 * Fulfill fetch Tournament Instance
 * @param action$
 * @param state$
 * @returns {*}
 */
export const fetchTournamentInstance = (action$) =>
    action$.pipe(
        ofType('TOURNAMENT_INSTANCE_FETCH'),
        throttle(() => race(action$.pipe(ofType('TOURNAMENT_INSTANCE_FETCH_FULFILLED')), timer(5000))),
        switchMap(({ payload }) => from(fetch(api + '/tournament/instances' + payload.uriParams, { credentials: 'include' }).then((res) => (res.status === 200 ? res.json() : null)))),
        map((res) => (res ? { type: 'TOURNAMENT_INSTANCE_FETCH_FULFILLED', payload: res } : { type: 'AUTH_UNAUTHORISED' }))
    )

/**
 * Fulfill delete Tournament Instance
 * @param action$
 * @param state$
 * @returns {*}
 */
export const deleteTournamentInstance = (action$) =>
    action$.pipe(
        ofType('TOURNAMENT_INSTANCE_DELETE'),
        throttle(() => race(action$.pipe(ofType('TOURNAMENT_INSTANCE_DELETE_FULFILLED')), timer(5000))),
        switchMap(({ payload }) => from(fetch(api + '/tournament/instance/' + payload.id, { credentials: 'include', method: 'DELETE' }).then((res) => (res.status === 200 ? res.json() : null)))),
        map((res) => (res ? { type: 'TOURNAMENT_INSTANCE_DELETE_FULFILLED', payload: res } : { type: 'AUTH_UNAUTHORISED' }))
    )

/**
 * View Tournament Points Template
 * @param action$
 * @returns {*}
 */
export const viewTournamentPointsTemplate = (action$) => action$.pipe(ofType('VIEW_TOURNAMENT_POINTS_TEMPLATE'), mapTo({ type: 'TOURNAMENT_POINTS_TEMPLATE_FETCH' }))

/**
 * Fulfill fetch Tournament Points Template
 * @param action$
 * @param state$
 * @returns {*}
 */
export const fetchTournamentPointsTemplate = (action$) =>
    action$.pipe(
        ofType('TOURNAMENT_POINTS_TEMPLATE_FETCH'),
        throttle(() => race(action$.pipe(ofType('TOURNAMENT_POINTS_TEMPLATE_FETCH_FULFILLED')), timer(5000))),
        switchMap(({ payload }) => from(fetch(api + '/tournament/pointsTemplates', { credentials: 'include' }).then((res) => (res.status === 200 ? res.json() : null)))),
        map((res) => (res ? { type: 'TOURNAMENT_POINTS_TEMPLATE_FETCH_FULFILLED', payload: res } : { type: 'AUTH_UNAUTHORISED' }))
    )

/**
 * Fulfill delete Tournament Points Template
 * @param action$
 * @param state$
 * @returns {*}
 */
export const deleteTournamentPointsTemplate = (action$) =>
    action$.pipe(
        ofType('TOURNAMENT_POINTSTEMPLATE_DELETE'),
        throttle(() => race(action$.pipe(ofType('TOURNAMENT_POINTSTEMPLATE_DELETE_FULFILLED')), timer(5000))),
        switchMap(({ payload }) => from(fetch(api + '/tournament/pointsTemplate/' + payload.id, { credentials: 'include', method: 'DELETE' }).then((res) => (res.status === 200 ? res.json() : null)))),
        map((res) => (res ? { type: 'TOURNAMENT_POINTSTEMPLATE_DELETE_FULFILLED', payload: res } : { type: 'AUTH_UNAUTHORISED' }))
    )
