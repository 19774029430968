/**
 * Module Redux Sidebar Reducer
 *
 * Author Matic Kogovšek [kogovsek.matic@gmail.com]
 */
/*!
 * Module dependencies.
 */
import { localStorageKey } from '../../config/store'

/**
 * Initial store state
 * @type {}
 */
const initialState = {
    show: 'responsive',
}
/**
 * Local store
 * @type {Object}
 */
const localStore = JSON.parse(localStorage.getItem(localStorageKey) || '{}')
/**
 * Sidebar reducer
 * @param state
 * @param action
 * @returns {{edit: {}, saved: {}, error: {}, synced: boolean, list: Array}}
 */
const sidebarReducer = (state = localStore.sidebar ? { ...localStore.sidebar } : initialState, action) => {
    switch (action.type) {
        case 'SIDEBAR_SET':
            return {
                ...state,
                show: action.payload,
            }
        default:
            return state
    }
}

export default sidebarReducer
