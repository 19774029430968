/*!
 * Module dependencies.
 */
import { localStorageKey } from '../../config/store'

/**
 * Initial store state
 * @type {}
 */
const initialState = {
    notifs: [],
}
/**
 * Local store
 * @type {Object}
 */
const localStore = JSON.parse(localStorage.getItem(localStorageKey) || '{}')

const notificationReducer = (state = localStore.notification ? { ...localStore.notification } : initialState, action) => {
    switch (action.type) {
        case 'NOTIFICATION_REPORT_CREATE':
            let notifications = { ...state }
            notifications.notifs.push(action.payload)
            return notifications
        case 'NOTIFICATION_REPORT_REMOVE':
            let n = { ...state }
            let newNotifs = n.notifs.filter((el, index, array) => {
                return el.to !== action.payload.to
            })
            return Object.assign(n, { notifs: newNotifs })
        default:
            return state
    }
}

export default notificationReducer
