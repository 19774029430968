/**
 * Module Redux View Reducer
 *
 * Author Matic Kogovšek [kogovsek.matic@gmail.com]
 */
/*!
 * Module dependencies.
 */
import { localStorageKey } from '../../config/store'

/**
 * Initial store state
 * @type {}
 */
const initialState = {
    lastPath: '/',
    breadcrumbs: [],
    history: [] /* history contains max of 20 entries */,
}
/**
 * Local store
 * @type {Object}
 */
const localStore = JSON.parse(localStorage.getItem(localStorageKey) || '{}')
/**
 * View reducer
 * @param state
 * @param action
 * @returns {{edit: {}, saved: {}, error: {}, synced: boolean, list: Array}}
 */
const viewReducer = (state = localStore.view ? { ...localStore.view } : initialState, action) => {
    switch (action.type) {
        case 'BREADCRUMBS_UPDATE':
            return {
                ...state,
                breadcrumbs: action.payload,
            }
        case 'LASTPATH_UPDATE':
            return {
                ...state,
                lastPath: action.payload,
            }
        case 'PUSH_REQ_HISTORY':
            let history_ = [...state.history]
            history_.unshift({ ...action.payload })
            if (history_.length > 20) history_.pop()
            return {
                ...state,
                history: history_,
            }
        default:
            return action.type.substring(0, 4) === 'VIEW' ? { ...state, lastPath: window.location.pathname } : state
    }
}

export default viewReducer
