import { ofType } from 'redux-observable'
import { from, race, timer } from 'rxjs'
import { map, mapTo, switchMap, throttle } from 'rxjs/operators'

import { api } from '../../config/api'

/**
 * View Mission Schedule
 * @param action$
 * @returns {*}
 */
export const viewMissionSchedule = (action$) => action$.pipe(ofType('VIEW_MISSION_SCHEDULE'), mapTo({ type: 'MISSION_SCHEDULE_FETCH' }))

/**
 * Fulfill fetch Mission Schedule
 * @param action$
 * @param state$
 * @returns {*}
 */
export const fetchMissionSchedule = (action$) =>
    action$.pipe(
        ofType('MISSION_SCHEDULE_FETCH'),
        throttle(() => race(action$.pipe(ofType('MISSION_SCHEDULE_FETCH_FULFILLED')), timer(5000))),
        switchMap(({ payload }) => from(fetch(api + '/mission/schedules', { credentials: 'include' }).then((res) => (res.status === 200 ? res.json() : null)))),
        map((res) => (res ? { type: 'MISSION_SCHEDULE_FETCH_FULFILLED', payload: res } : { type: 'AUTH_UNAUTHORISED' }))
    )

/**
 * Fulfill delete Mission Schedule
 * @param action$
 * @param state$
 * @returns {*}
 */
export const deleteMissionSchedule = (action$) =>
    action$.pipe(
        ofType('MISSION_SCHEDULE_DELETE'),
        throttle(() => race(action$.pipe(ofType('MISSION_SCHEDULE_DELETE_FULFILLED')), timer(5000))),
        switchMap(({ payload }) => from(fetch(api + '/mission/schedule/' + payload.id, { credentials: 'include', method: 'DELETE' }).then((res) => (res.status === 200 ? res.json() : null)))),
        map((res) => (res ? { type: 'MISSION_SCHEDULE_DELETE_FULFILLED', payload: res } : { type: 'AUTH_UNAUTHORISED' }))
    )

/**
 * View Mission Instance
 * @param action$
 * @returns {*}
 */
export const viewMissionInstance = (action$) => action$.pipe(ofType('VIEW_MISSION_INSTANCE'), mapTo({ type: 'MISSION_INSTANCE_FETCH' }))

/**
 * Fulfill fetch Mission Instance
 * @param action$
 * @param state$
 * @returns {*}
 */
export const fetchMissionInstance = (action$) =>
    action$.pipe(
        ofType('MISSION_INSTANCE_FETCH'),
        throttle(() => race(action$.pipe(ofType('MISSION_INSTANCE_FETCH_FULFILLED')), timer(5000))),
        switchMap(({ payload }) => from(fetch(api + '/mission/instances', { credentials: 'include' }).then((res) => (res.status === 200 ? res.json() : null)))),
        map((res) => (res ? { type: 'MISSION_INSTANCE_FETCH_FULFILLED', payload: res } : { type: 'AUTH_UNAUTHORISED' }))
    )

/**
 * Fulfill delete Mission Instance
 * @param action$
 * @param state$
 * @returns {*}
 */
export const deleteMissionInstance = (action$) =>
    action$.pipe(
        ofType('MISSION_INSTANCE_DELETE'),
        throttle(() => race(action$.pipe(ofType('MISSION_INSTANCE_DELETE_FULFILLED')), timer(5000))),
        switchMap(({ payload }) => from(fetch(api + '/mission/instance/' + payload.id, { credentials: 'include', method: 'DELETE' }).then((res) => (res.status === 200 ? res.json() : null)))),
        map((res) => (res ? { type: 'MISSION_INSTANCE_DELETE_FULFILLED', payload: res } : { type: 'AUTH_UNAUTHORISED' }))
    )
