/**
 * Module Redux Feed Epics
 *
 * Author Matic Kogovšek [kogovsek.matic@gmail.com]
 */
/*!
 * Module dependencies.
 */
import { ofType } from 'redux-observable'
import { from, race, timer } from 'rxjs'
import { filter, map, mapTo, switchMap, throttle } from 'rxjs/operators'

import { api } from '../../config/api'
import history from '../../history'

/**
 * Handle Unauthorised
 * @param action$
 * @param state$
 * @returns {*}
 */
export const handleAuthUnauthorised = (action$) =>
    action$.pipe(
        ofType('AUTH_UNAUTHORISED'),
        filter(() => history.push('/login') && false)
    )

/**
 * Handle Login
 * @param action$
 * @param state$
 * @returns {*}
 */

export const handleAuthLogin = (action$) =>
    action$.pipe(
        ofType('AUTH_LOGIN'),
        throttle(() => race(action$.pipe(ofType('AUTH_LOGIN_SUCCESS')), action$.pipe(ofType('AUTH_LOGIN_FAILED')), timer(5000))),
        switchMap(({ payload }) =>
            from(
                fetch(api + '/login', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include',
                    body: JSON.stringify(payload),
                }).then((response) => (response.status === 200 ? response.json() : 'FAILED'))
            )
        ),
        map((res) => (res !== 'FAILED' ? { type: 'AUTH_LOGIN_SUCCESS', payload: res } : { type: 'AUTH_LOGIN_FAILED' }))
    )

/**
 * Handle Logout
 * @param action$
 * @param state$
 * @returns {*}
 */
export const handleAuthLogout = (action$) =>
    action$.pipe(
        ofType('AUTH_LOGOUT'),
        throttle(() => race(action$.pipe(ofType('AUTH_LOGOUT')), timer(5000))),
        switchMap(({ payload }) => from(fetch(api + '/logout', { credentials: 'include' }))),
        map((res) => (res.status === 200 ? { type: 'AUTH_LOGOUT_SUCCESS' } : { type: 'AUTH_LOGOUT_FAILED' }))
    )

/**
 * Handle Logout
 * @param action$
 * @param state$
 * @returns {*}
 */
export const handleLoggedOut = (action$) =>
    action$.pipe(
        ofType('AUTH_LOGOUT_SUCCESS'),
        filter(() => history.push('/login') && false)
    )

/**
 * Handle Account data resync
 * @param action$
 * @param state$
 * @returns {*}
 */
export const handleAuthResync = (action$) =>
    action$.pipe(
        ofType('AUTH_DATA_RESYNC'),
        throttle(() => race(action$.pipe(ofType('AUTH_DATA_RESYNC')), timer(5000))),
        switchMap(({ payload }) => from(fetch(api + '/auth_resync', { credentials: 'include' }).then((response) => (response.status === 200 ? response.json() : 'FAILED')))),
        map((res) => (res !== 'FAILED' ? { type: 'AUTH_RESYNC_SUCCESS', payload: res } : { type: 'AUTH_RESYNC_FAILED' }))
    )

export const doTheUtilEntityResync = (action$, state$) => action$.pipe(ofType('AUTH_LOGIN_SUCCESS'), mapTo({ type: 'UTILITY_ENTITY_FETCH' }))
