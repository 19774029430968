/**
 * Module Redux Tournament Reducer
 *
 * Author Matic Kogovšek [kogovsek.matic@gmail.com]
 */
/*!
 * Module dependencies.
 */
import { localStorageKey } from '../../config/store'

/**
 * Initial store state
 * @type {}
 */
const initialState = {
    schedule: [],
    scheduleIsFetching: false,
    scheduleSynced: new Date(0),

    instance: {},
    instanceIsFetching: false,
    instanceSynced: new Date(0),

    pointsTemplate: [],
    pointsTemplateIsFetching: false,
    pointsTemplateSynced: new Date(0),

    codebooks: {
        campagin_data: [],
        campagin_synced: new Date(0),
        campagin_isFetching: false,
    },
}
/**
 * Local store
 * @type {Object}
 */
const localStore = JSON.parse(localStorage.getItem(localStorageKey) || '{}')
/**
 * Feed reducer
 * @param state
 * @param action
 * @returns {{edit: {}, saved: {}, error: {}, synced: boolean, list: Array}}
 */
const tournamentReducer = (state = localStore.tournament ? { ...localStore.tournament } : initialState, action) => {
    switch (action.type) {
        case 'TOURNAMENT_SCHEDULE_UPDATE':
            return {
                ...state,
                schedule: state.schedule.find((ts) => ts.id === action.payload.id)
                    ? state.schedule.map((ts) => (ts.id === action.payload.id ? action.payload : ts))
                    : [action.payload, ...state.schedule],
                scheduleSynced: new Date(),
            }
        case 'TOURNAMENT_INSTANCE_UPDATE':
            let curInstState = { ...state.instance }
            if ((state.instance.data || []).length > 0) {
                // check if we have something in state
                if ((state.instance.data || []).find((ti) => ti.id === action.payload.id)) {
                    // if we already have document with id, just update
                    curInstState.data = state.instance.data.map((ti) => (ti.id === action.payload.id ? action.payload : ti))
                } else {
                    // just add current to existing state
                    curInstState.data.push(action.payload)
                }
            } else {
                // state is empty - aka initial state
                curInstState = {
                    pagination: {
                        num_rows: 1,
                        skip_rows: 0,
                        limit_rows: 20,
                    },
                    data: [action.payload],
                }
            }
            return {
                ...state,
                instance: curInstState,
                instanceSynced: new Date(),
            }
        case 'TOURNAMENT_SCHEDULE_FETCH':
            return {
                ...state,
                scheduleIsFetching: true,
            }
        case 'TOURNAMENT_SCHEDULE_FETCH_FULFILLED':
            return {
                ...state,
                schedule: action.payload,
                scheduleIsFetching: false,
                scheduleSynced: new Date(),
            }
        case 'TOURNAMENT_INSTANCE_FETCH':
            return {
                ...state,
                instanceIsFetching: true,
            }
        case 'TOURNAMENT_INSTANCE_FETCH_FULFILLED':
            return {
                ...state,
                instance: action.payload,
                instanceIsFetching: false,
                instanceSynced: new Date(),
            }
        case 'TOURNAMENT_SCHEDULE_DELETE_FULFILLED':
            return {
                ...state,
                schedule: state.schedule.filter((ts) => ts.id !== action.payload.id),
                scheduleSynced: new Date(),
            }
        case 'TOURNAMENT_INSTANCE_DELETE_FULFILLED':
            let cis = { ...state.instance }
            if (cis.data) cis.data = cis.data.filter((ti) => ti.id !== action.payload.id)
            return {
                ...state,
                instance: cis, // state.instance.filter((ts) => ts.id !== action.payload.id),
                instanceSynced: new Date(),
            }
        case 'TOURNAMENT_POINTS_TEMPLATE_FETCH':
            return {
                ...state,
                pointsTemplateIsFetching: true,
            }
        case 'TOURNAMENT_POINTS_TEMPLATE_FETCH_FULFILLED':
            return {
                ...state,
                pointsTemplate: action.payload,
                pointsTemplateIsFetching: false,
                pointsTemplateSynced: new Date(),
            }
        case 'TOURNAMENT_POINTSTEMPLATE_UPDATE':
            return {
                ...state,
                pointsTemplate: state.pointsTemplate.find((ti) => ti.id === action.payload.id)
                    ? state.pointsTemplate.map((ti) => (ti.id === action.payload.id ? action.payload : ti))
                    : [action.payload, ...state.pointsTemplate],
                pointsTemplateSynced: new Date(),
            }
        case 'TOURNAMENT_POINTSTEMPLATE_DELETE_FULFILLED':
            return {
                ...state,
                pointsTemplate: state.pointsTemplate.filter((ts) => ts.id !== action.payload.id),
                pointsTemplateSynced: new Date(),
            }
        default:
            return state
    }
}

export default tournamentReducer
