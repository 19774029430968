/**
 * Module Redux Tournament Reducer
 *
 * Author Matic Kogovšek [kogovsek.matic@gmail.com]
 */
/*!
 * Module dependencies.
 */
import { localStorageKey } from '../../config/store'

/**
 * Initial store state
 * @type {}
 */
const initialState = {
    /*trxPerEntitySynced: new Date(0)
    , trxPerEntityData: []
    */
    gameTypeDataSynced: new Date(0),
    gameTypeData: [],
}
/**
 * Local store
 * @type {Object}
 */
const localStore = JSON.parse(localStorage.getItem(localStorageKey) || '{}')
/**
 * Dashboard reducer
 * @param state
 * @param action
 * @returns {{edit: {}, saved: {}, error: {}, synced: boolean, list: Array}}
 */
const dashboardReducer = (state = localStore.dashboard ? { ...localStore.dashboard } : initialState, action) => {
    switch (action.type) {
        // display num of trx in last hour on dashboard; not in use atm
        /*
        case "DASHBOARD_TRX_PER_ENTITY_DATA_FETCH_FULFILLED":
            return {
                ...state
                , trxPerEntityData: action.payload
                , trxPerEntitySynced: new Date()
            };
        */
        case 'DASHBOARD_GAME_TYPE_DATA_FETCH_FULFILLED':
            return {
                ...state,
                gameTypeData: action.payload,
                gameTypeDataSynced: new Date(),
            }
        default:
            return state
    }
}

export default dashboardReducer
