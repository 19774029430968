/**
 * Module Redux Feed Epics
 */
/*!
 * Module dependencies.
 */
import { ofType } from 'redux-observable'
import { from, race, timer } from 'rxjs'
import { map, switchMap, throttle } from 'rxjs/operators'

import { api } from '../../config/api'

/**
 * View Audit Log
 * @param action$
 * @returns {*}
 */
export const viewApiLogs = (action$) =>
    action$.pipe(
        ofType('VIEW_API_LOGS'),
        map(({ payload }) => ({ type: 'API_LOGS_FETCH', payload: payload }))
    )

/**
 * Fulfill fetch API Log
 * @param action$
 * @param state$
 * @returns {*}
 */
export const fetchApiLog = (action$) =>
    action$.pipe(
        ofType('API_LOGS_FETCH'),
        throttle(() => race(action$.pipe(ofType('API_LOGS_FETCH_FULFILLED')), timer(5000))),
        switchMap(({ payload }) => from(fetch(api + '/system/api-logs' + payload.uriParams, { credentials: 'include' }).then((res) => (res.status === 200 ? res.json() : null)))),
        map((res) => (res ? { type: 'API_LOGS_FETCH_FULFILLED', payload: res } : { type: 'AUTH_UNAUTHORISED' }))
    )
