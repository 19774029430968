/*!
 * Module dependencies.
 */
import { localStorageKey } from '../../config/store'

/**
 * Initial store state
 * @type {}
 */
const initialState = {
    reportPerPlayer: {},
    reportPerGame: {},
    reportPerGameType: {},
    reportPerEntity: {},
    reportPerCurrency: {},
    reportJackpot: {},
    reportJackpotTx: {},
}
/**
 * Local store
 * @type {Object}
 */
const localStore = JSON.parse(localStorage.getItem(localStorageKey) || '{}')
/**
 * Feed reducer
 * @param state
 * @param action
 * @returns {{edit: {}, saved: {}, error: {}, synced: boolean, list: Array}}
 */
const reportReducer = (state = localStore.report ? { ...localStore.report } : initialState, action) => {
    switch (action.type) {
        case 'REPORT_PLAYER_FETCH_FULFILLED':
            return {
                ...state,
                reportPerPlayer: action.payload,
            }
        case 'REPORT_GAME_FETCH_FULFILLED':
            return {
                ...state,
                reportPerGame: action.payload,
            }
        case 'REPORT_GAMETYPE_FETCH_FULFILLED':
            return {
                ...state,
                reportPerGameType: action.payload,
            }
        case 'REPORT_ENTITY_FETCH_FULFILLED':
            return {
                ...state,
                reportPerEntity: action.payload,
            }
        case 'REPORT_CURRENCY_FETCH_FULFILLED':
            return {
                ...state,
                reportPerCurrency: action.payload,
            }
        case 'REPORT_JACKPOT_FETCH_FULFILLED':
            return {
                ...state,
                reportJackpot: action.payload,
            }
        case 'REPORT_JACKPOTTX_FETCH_FULFILLED':
            return {
                ...state,
                reportJackpotTx: action.payload,
            }
        default:
            return state
    }
}

export default reportReducer
