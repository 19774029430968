/**
 * Module Redux Utility Reducer
 *
 * Author Matic Kogovšek [kogovsek.matic@gmail.com]
 */
/*!
 * Module dependencies.
 */
import { localStorageKey } from '../../config/store'

/**
 * Initial store state
 * @type {}
 */
const initialState = {
    games: [],
    games_synced: new Date(0),
    games_res_succeeded: null,
    games_req_processing: null,

    currencies: [],
    currencies_synced: new Date(0),
    currencies_res_succeeded: null,
    currencies_req_processing: null,

    entities: [],
    entities_synced: new Date(0),
    entities_res_succeeded: null,
    entities_req_processing: null,

    gameGSheet: [],
    gameGSheet_synced: new Date(0),
    gameGSheet_isFetching: false,
    gameGSheet_res_succeeded: null,

    entityGroups: [],
    entityGroups_synced: new Date(0),
    entityGroups_res_succeeded: null,
    entityGroups_req_processing: null,

    walletGroups: [],
    walletGroups_synced: new Date(0),
    walletGroups_res_succeeded: null,
    walletGroups_req_processing: null,

    platforms: [],
    platforms_synced: new Date(0),
    platforms_res_succeeded: null,
    platforms_req_processing: null,
}
/**
 * Local store
 * @type {Object}
 */
const localStore = JSON.parse(localStorage.getItem(localStorageKey) || '{}')
/**
 * Utility reducer
 * @param state
 * @param action
 * @returns {{edit: {}, saved: {}, error: {}, synced: boolean, list: Array}}
 */
const utilityReducer = (state = localStore.util ? { ...localStore.util } : initialState, action) => {
    switch (action.type) {
        case 'UTILITY_GAME_FETCH':
            return {
                ...state,
                games_req_processing: true,
            }
        case 'UTILITY_GAME_FETCH_FULFILLED':
            return {
                ...state,
                games: action.payload,
                games_synced: new Date(),
                games_res_succeeded: true,
                games_req_processing: false,
            }
        case 'UTILITY_GAME_FETCH_NET_PREOBLEM':
            return {
                ...state,
                games_res_succeeded: false,
                games_req_processing: false,
            }

        case 'UTILITY_CURRENCY_FETCH':
            return {
                ...state,
                currencies_req_processing: true,
            }
        case 'UTILITY_CURRENCY_FETCH_FULFILLED':
            return {
                ...state,
                currencies: action.payload,
                currencies_synced: new Date(),
                currencies_res_succeeded: true,
                currencies_req_processing: false,
            }
        case 'UTILITY_CURRENCY_FETCH_NET_PREOBLEM':
            return {
                ...state,
                currencies_res_succeeded: false,
                currencies_req_processing: false,
            }

        case 'UTILITY_ENTITY_FETCH':
            return {
                ...state,
                entities_req_processing: true,
            }
        case 'UTILITY_ENTITY_FETCH_FULFILLED':
            return {
                ...state,
                entities: action.payload,
                entities_synced: new Date(),
                entities_res_succeeded: true,
                entities_req_processing: false,
            }
        case 'UTILITY_ENTITY_FETCH_NET_PREOBLEM':
            return {
                ...state,
                entities_res_succeeded: false,
                entities_req_processing: false,
            }

        case 'UTILITY_GAME_GSHEET_FETCH':
            return {
                ...state,
                gameGSheet_isFetching: true,
            }
        case 'UTILITY_GAME_GSHEET_FETCH_FULFILLED':
            return {
                ...state,
                gameGSheet: action.payload,
                gameGSheet_synced: new Date(),
                gameGSheet_isFetching: false,
                gameGSheet_res_succeeded: true,
            }
        case 'UTILITY_GAME_GSHEET_FETCH_NET_PREOBLEM':
            return {
                ...state,
                gameGSheet_isFetching: false,
                gameGSheet_res_succeeded: false,
            }

        case 'UTILITY_ENTITY_GROUP_FETCH':
            return {
                ...state,
                entityGroups_req_processing: true,
            }
        case 'UTILITY_ENTITY_GROUP_FETCH_FULFILLED':
            return {
                ...state,
                entityGroups: action.payload,
                entityGroups_synced: new Date(),
                entityGroups_res_succeeded: true,
                entityGroups_req_processing: false,
            }

        case 'UTILITY_WALLET_GROUP_FETCH_FULFILLED':
            return {
                ...state,
                walletGroups: action.payload,
                walletGroups_synced: new Date(),
                walletGroups_res_succeeded: true,
                walletGroups_req_processing: false,
            }

        case 'UTILITY_ENTITY_GROUP_FETCH_NET_PREOBLEM':
            return {
                ...state,
                entityGroups_res_succeeded: false,
                entityGroups_req_processing: false,
            }
        case 'UTILITY_ENTITY_GROUP_DELETE_FULFILLED':
            return {
                ...state,
                entityGroups: state.entityGroups.filter((eg) => eg.id !== action.payload.id),
                entityGroups_res_succeeded: true,
                entityGroups_req_processing: false,
                entityGroups_synced: new Date(),
            }
        case 'UTILITY_ENTITY_GROUP_UPDATE_FULFILLED':
            return {
                ...state,
                entityGroups: state.entityGroups.find((eg) => eg.id === action.payload.id)
                    ? state.entityGroups.map((eg) => (eg.id === action.payload.id ? action.payload : eg))
                    : [action.payload, ...state.entityGroups],
                entityGroups_synced: new Date(),
            }

        case 'UTILITY_PLATFORM_FETCH':
            return {
                ...state,
                platforms_req_processing: true,
            }
        case 'UTILITY_PLATFORM_FETCH_FULFILLED':
            return {
                ...state,
                platforms: action.payload,
                platforms_synced: new Date(),
                platforms_res_succeeded: true,
                platforms_req_processing: false,
            }
        case 'UTILITY_PLATFORM_FETCH_NET_PREOBLEM':
            return {
                ...state,
                platforms_res_succeeded: false,
                platforms_req_processing: false,
            }

        case 'UTILITY_ENTITY_GROUP_DO_NOTHING':
        default:
            return state
    }
}

export default utilityReducer
