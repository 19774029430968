/**
 * Module Redux Tournament Actions
 *
 * Author Matic Kogovšek [kogovsek.matic@gmail.com]
 */
/*!
 * Module dependencies.
 */

/**
 * View Tournament Schedule action
 * @returns {{type: string, payload: {}}}
 */
export const authLogin = (payload = {}) => ({ type: 'AUTH_LOGIN', payload })

export const authLogout = (payload = {}) => ({ type: 'AUTH_LOGOUT', payload })

export const authSync = (payload = {}) => ({ type: 'AUTH_DATA_RESYNC', payload })
