/**
 * Module Redux Jackpot Reducer
 *
 * Author Matic Kogovšek [kogovsek.matic@gmail.com]
 */
/*!
 * Module dependencies.
 */
import { localStorageKey } from '../../config/store'

/**
 * Initial store state
 * @type {}
 */
const initialState = {
    schedule: [],
    scheduleIsFetching: false,
    scheduleSynced: new Date(0),

    instance: [],
    instanceIsFetching: false,
    instanceSynced: new Date(0),

    jpContributions: [],
}
/**
 * Local store
 * @type {Object}
 */
const localStore = JSON.parse(localStorage.getItem(localStorageKey) || '{}')
/**
 * Jackpot reducer
 * @param state
 * @param action
 * @returns {{edit: {}, saved: {}, error: {}, synced: boolean, list: Array}}
 */
const jackpotReducer = (state = localStore.jackpot ? { ...localStore.jackpot } : initialState, action) => {
    switch (action.type) {
        case 'JACKPOT_SCHEDULE_UPDATE':
            return {
                ...state,
                schedule: state.schedule.find((ts) => ts.id === action.payload.id)
                    ? state.schedule.map((ts) => (ts.id === action.payload.id ? action.payload : ts))
                    : [action.payload, ...state.schedule],
                scheduleSynced: new Date(),
            }
        case 'JACKPOT_INSTANCE_UPDATE':
            return {
                ...state,
                instance: state.instance.find((ti) => ti.id === action.payload.id)
                    ? state.instance.map((ti) => (ti.id === action.payload.id ? action.payload : ti))
                    : [action.payload, ...state.instance],
                instanceSynced: new Date(),
            }
        case 'JACKPOT_SCHEDULE_FETCH':
            return {
                ...state,
                scheduleIsFetching: true,
            }
        case 'JACKPOT_SCHEDULE_FETCH_FULFILLED':
            return {
                ...state,
                schedule: action.payload,
                scheduleIsFetching: false,
                scheduleSynced: new Date(),
            }
        case 'JACKPOT_INSTANCE_FETCH':
            return {
                ...state,
                instanceIsFetching: true,
            }
        case 'JACKPOT_INSTANCE_FETCH_FULFILLED':
            return {
                ...state,
                instance: action.payload,
                instanceIsFetching: false,
                instanceSynced: new Date(),
            }
        case 'JACKPOT_SCHEDULE_DELETE_FULFILLED':
            return {
                ...state,
                schedule: state.schedule.filter((ts) => ts.id !== action.payload.id),
                scheduleSynced: new Date(),
            }
        case 'JACKPOT_INSTANCE_DELETE_FULFILLED':
            return {
                ...state,
                instance: state.instance.filter((ts) => ts.id !== action.payload.id),
                instanceSynced: new Date(),
            }
        case 'VIEW_JACKPOT_INSTANCE_CONTRIBUTIONS_FETCH_FULFILLED':
            return {
                ...state,
                jpContributions: action.payload,
            }
        default:
            return state
    }
}

export default jackpotReducer
