/**
 * Module Redux System Reducer
 *
 * Author Matic Kogovšek [kogovsek.matic@gmail.com]
 */
/*!
 * Module dependencies.
 */
import { localStorageKey } from '../../config/store'

/**
 * Initial store state
 * @type {}
 */
const initialState = {
    users: [],
    isFetching: false,
    synced: new Date(0),
    messageHooks: {
        messageHooks: [],
        isFetching: false,
        synced: new Date(0),
    },
}
/**
 * Local store
 * @type {Object}
 */
const localStore = JSON.parse(localStorage.getItem(localStorageKey) || '{}')
/**
 * System reducer
 * @param state
 * @param action
 * @returns {{edit: {}, saved: {}, error: {}, synced: boolean, list: Array}}
 */
const systemReducer = (state = localStore.system ? { ...localStore.system } : initialState, action) => {
    switch (action.type) {
        case 'SYSTEM_USER_FETCH':
            return {
                ...state,
                isFetching: true,
            }
        case 'SYSTEM_USER_FETCH_FULFILLED':
            return {
                ...state,
                users: action.payload,
                isFetching: false,
                synced: new Date(),
            }
        case 'SYSTEM_USER_DELETE_FULFILLED':
            return {
                ...state,
                users: state.users.filter((u) => u._id !== action.payload._id),
                synced: new Date(),
            }
        case 'SYSTEM_USER_UPDATE':
            return {
                ...state,
                users: state.users.find((u) => u._id === action.payload._id) ? state.users.map((u) => (u._id === action.payload._id ? action.payload : u)) : [action.payload, ...state.users],
                synced: new Date(),
            }
        case 'SYSTEM_MESSAGE_HOOKS_FETCH':
            return {
                ...state,
                messageHooks: {
                    ...state.messageHooks,
                    isFetching: true,
                },
            }
        case 'SYSTEM_MESSAGE_HOOKS_FETCH_FULFILLED':
            return {
                ...state,
                messageHooks: {
                    messageHooks: action.payload,
                    isFetching: false,
                    synced: new Date(),
                },
            }
        case 'SYSTEM_MESSAGE_HOOK_DELETE_FULFILLED':
            return {
                ...state,
                messageHooks: {
                    messageHooks: state.messageHooks.messageHooks.filter((smh) => smh.id !== action.payload.id),
                    isFetching: false,
                    synced: new Date(),
                },
            }
        case 'SYSTEM_MESSAGE_HOOK_UPDATE':
            return {
                ...state,
                messageHooks: {
                    messageHooks: state.messageHooks.messageHooks.find((cmh) => cmh.id === action.payload.id)
                        ? state.messageHooks.messageHooks.map((cmh2) => (cmh2.id === action.payload.id ? action.payload : cmh2))
                        : [action.payload, ...state.messageHooks.messageHooks],
                    isFetching: false,
                    synced: new Date(),
                },
            }
        default:
            return state
    }
}

export default systemReducer
