import 'react-app-polyfill/ie11' // For IE 11 support
import 'react-app-polyfill/stable'
import 'core-js'
import './polyfill'
import 'bootstrap/dist/css/bootstrap.min.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import { api } from '../src/config/api'
import App from './App'
import { icons } from './assets/icons'
import { authSync } from './redux/actions/auth'
import { utilitySync } from './redux/actions/util'
import * as serviceWorker from './serviceWorker'
// Redux store
import store from './store'

React.icons = icons

// check if the request came from "telegram chat"
// a.k.a. - possibly unauthenticated user (who did not yet open Relayer backoffice and logged in)
// clicked on a message from the telegram bot
const authRegexStr = new RegExp(/\/notifications\/telegram\/chat\/(?<id>[a-z0-9]{24})\?auth=(?<auth>\S{36})/)
const regRes = authRegexStr.exec(window.location.href)

if (regRes && regRes.length === 3) {
    const id = regRes[1]
    const telegramAuth = regRes[2]

    fetch(api + '/telegramAuth/' + id + '?auth=' + telegramAuth, { credentials: 'include' })
        .then((response) => {
            if (response.status === 200) {
                return response.json()
            } else {
                //return 'FAILED'

                // throw error so we do not try to refetch all the coodebooks, since we know we are not authorized
                // and all those subsequent request would also be http satus 401
                throw Error(response.status)
            }
        })
        /*
        .then((data) => {
            if (data !== 'FAILED') {
                store.dispatch({ type: 'AUTH_RESYNC_SUCCESS', payload: data })
                return true
            } else {
                store.dispatch({ type: 'AUTH_RESYNC_FAILED' })
                return false
            }
        })
        .then((authSuccessful) => {
            if (authSuccessful) {
                return store.dispatch(utilitySync())
            } else {
                return true
            }
        })
        */
        .then((json) => store.dispatch({ type: 'AUTH_RESYNC_SUCCESS', payload: json }))
        .then(() => store.dispatch(utilitySync()))
        .then(() => {
            ReactDOM.render(
                <Provider store={store}>
                    <App />
                </Provider>,
                document.getElementById('root')
            )
        })
        .catch((err) => {
            const statusCode = err.message || ''
            switch (statusCode) {
                case '403':
                    alert('Forbidden')
                    store.dispatch({ type: 'AUTH_RESYNC_CLEAR_REDIRECT_URL', payload: {} })
                    window.location.href = window.location.origin + '/login'
                    break
                case '404':
                    alert('Telegram room settings not found. Please log in and try to find it manually')
                    store.dispatch({ type: 'AUTH_RESYNC_CLEAR_REDIRECT_URL', payload: {} })
                    window.location.href = window.location.origin + '/login'
                    break
                case '406':
                    alert('Authorization missing! Please contact the support team.')
                    store.dispatch({ type: 'AUTH_RESYNC_CLEAR_REDIRECT_URL', payload: {} })
                    window.location.href = window.location.origin + '/dashboard'
                    break
                case '412':
                    alert('The authentication has key expired! Please refresh it or generate a new link')
                    store.dispatch({ type: 'AUTH_RESYNC_CLEAR_REDIRECT_URL', payload: {} })
                    window.location.href = window.location.origin + '/dashboard'
                    break
                default:
                    alert('Please authenticate first')
                    store.dispatch({ type: 'AUTH_RESYNC_SAVE_REDIRECT_URL', payload: window.location.href })
                    window.location.href = window.location.origin + '/login'
                    break
            }
        })
} else {
    // Try to refresh state.auth of possibly logged in user
    // (if authentificated user decides to refresh page, then we need to refresh state.auth since it is default/empty at the start)
    store.dispatch(authSync())
    // Dispatch initial action
    store.dispatch(utilitySync())

    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById('root')
    )
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
