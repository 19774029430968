/**
 * Module Redux Feed Epics
 *
 * Author Matic Kogovšek [kogovsek.matic@gmail.com]
 */
/*!
 * Module dependencies.
 */
import { ofType } from 'redux-observable'
import { from, race, timer } from 'rxjs'
import { map, mapTo, switchMap, throttle } from 'rxjs/operators'

import { api } from '../../config/api'

/**
 * View Jackpot Schedule
 * @param action$
 * @returns {*}
 */
export const viewJackpotSchedule = (action$) => action$.pipe(ofType('VIEW_JACKPOT_SCHEDULE'), mapTo({ type: 'JACKPOT_SCHEDULE_FETCH' }))

/**
 * Fulfill fetch Jackpot Schedule
 * @param action$
 * @param state$
 * @returns {*}
 */
export const fetchJackpotSchedule = (action$) =>
    action$.pipe(
        ofType('JACKPOT_SCHEDULE_FETCH'),
        throttle(() => race(action$.pipe(ofType('JACKPOT_SCHEDULE_FETCH_FULFILLED')), timer(5000))),
        switchMap(({ payload }) => from(fetch(api + '/jackpot/schedules', { credentials: 'include' }).then((res) => (res.status === 200 ? res.json() : null)))),
        map((res) => (res ? { type: 'JACKPOT_SCHEDULE_FETCH_FULFILLED', payload: res } : { type: 'AUTH_UNAUTHORISED' }))
    )

/**
 * Fulfill delete Jackpot Schedule
 * @param action$
 * @param state$
 * @returns {*}
 */
export const deleteJackpotSchedule = (action$) =>
    action$.pipe(
        ofType('JACKPOT_SCHEDULE_DELETE'),
        throttle(() => race(action$.pipe(ofType('JACKPOT_SCHEDULE_DELETE_FULFILLED')), timer(5000))),
        switchMap(({ payload }) => from(fetch(api + '/jackpot/schedule/' + payload.id, { credentials: 'include', method: 'DELETE' }).then((res) => (res.status === 200 ? res.json() : null)))),
        map((res) => (res ? { type: 'JACKPOT_SCHEDULE_DELETE_FULFILLED', payload: res } : { type: 'AUTH_UNAUTHORISED' }))
    )

/**
 * View Jackpot Instance
 * @param action$
 * @returns {*}
 */
export const viewJackpotInstance = (action$) =>
    action$.pipe(
        ofType('VIEW_JACKPOT_INSTANCE'),
        map(({ payload }) => ({ type: 'JACKPOT_INSTANCE_FETCH', payload: payload }))
    )

/**
 * Fulfill fetch Jackpot Instance
 * @param action$
 * @param state$
 * @returns {*}
 */
export const fetchJackpotInstance = (action$) =>
    action$.pipe(
        ofType('JACKPOT_INSTANCE_FETCH'),
        throttle(() => race(action$.pipe(ofType('JACKPOT_INSTANCE_FETCH_FULFILLED')), timer(5000))),
        switchMap(({ payload }) => from(fetch(api + '/jackpot/instancesOfSchedule?jsid=' + payload.id, { credentials: 'include' }).then((res) => (res.status === 200 ? res.json() : null)))),
        map((res) => (res ? { type: 'JACKPOT_INSTANCE_FETCH_FULFILLED', payload: res } : { type: 'AUTH_UNAUTHORISED' }))
    )

/**
 * Fulfill delete Jackpot Instance
 * @param action$
 * @param state$
 * @returns {*}
 */
export const deleteJackpotInstance = (action$) =>
    action$.pipe(
        ofType('JACKPOT_INSTANCE_DELETE'),
        throttle(() => race(action$.pipe(ofType('JACKPOT_INSTANCE_DELETE_FULFILLED')), timer(5000))),
        switchMap(({ payload }) => from(fetch(api + '/jackpot/instance/' + payload.id, { credentials: 'include', method: 'DELETE' }).then((res) => (res.status === 200 ? res.json() : null)))),
        map((res) => (res ? { type: 'JACKPOT_INSTANCE_DELETE_FULFILLED', payload: res } : { type: 'AUTH_UNAUTHORISED' }))
    )

/**
 * View Jackpot Instance Contributions
 * @param action$
 * @returns {*}
 */
export const viewJackpotInstanceContributions = (action$) =>
    action$.pipe(
        ofType('VIEW_JACKPOT_INSTANCE_CONTRIBUTIONS'),
        map(({ payload }) => ({ type: 'VIEW_JACKPOT_INSTANCE_CONTRIBUTIONS_FETCH', payload: payload }))
    )

/**
 * Fulfill fetch Jackpot Instance
 * @param action$
 * @param state$
 * @returns {*}
 */
export const fetchJackpotInstanceContributions = (action$) =>
    action$.pipe(
        ofType('VIEW_JACKPOT_INSTANCE_CONTRIBUTIONS_FETCH'),
        throttle(() => race(action$.pipe(ofType('VIEW_JACKPOT_INSTANCE_CONTRIBUTIONS_FETCH_FULFILLED')), timer(5000))),
        switchMap(({ payload }) => from(fetch(api + '/jackpot/instanceContributions?id=' + payload.id, { credentials: 'include' }).then((res) => (res.status === 200 ? res.json() : null)))),
        map((res) => (res ? { type: 'VIEW_JACKPOT_INSTANCE_CONTRIBUTIONS_FETCH_FULFILLED', payload: res } : { type: 'AUTH_UNAUTHORISED' }))
    )
